import { defineStore } from 'pinia'
import {ErrorLocationType, ErrorType, PopupType, PreferenceType, SsoType} from "@/utilities/enums/Enums";
import ErrorHandler from "@/utilities/helpers/ErrorHandler";
import FilterService from "@/utilities/services/FilterService";
import {ColumnStore, Store} from "@bryntum/grid";
import {HubNotification} from "@/models/HubNotification";
import {UserService} from "@/utilities/services/entities/UserService";
import NullOrUndefinedHelper from "@/utilities/helpers/NullOrUndefinedHelper";
import {AttributeFilter} from "@/models/AttributeFilter";
import {UserInfo} from "@/models/user/UserInfo";
import {StartupConfig} from "@/models/StartupConfig";
import {SystemCompanySettings} from "@/models/SystemCompanySettings";
import {UserPreference} from "@/models/user/UserPreference";
import router from "@/router";
import {DisdiSetting} from "@/models/setting/disdi/DisdiSetting";
import {ActivitySetting} from "@/models/setting/ActivitySetting";
import {permissionColumn} from "@/AppConfig";

export const useGlobalStore = defineStore('GlobalStore', {
    state: () => {
        return {
            refreshIsActive: true,
            gridDataIsLoading: false,
            currentItems: 0,
            allItems: 0,
            layout: "simple-layout", //"app-layout",
            success: {
                show: false,
                text: "Gespeichert!",
            },
            error: {
                location: ErrorLocationType.none,
                type: ErrorType.warning,
                text: "Fehler!",
            },
            modal: {
                show: false,
                validator: { $validate:()=>{} },
                resetButton: false,
                title: "Default Popup Title",
                popupType: PopupType.none,
                contentObject: {},
                action: ()=>{},
                bigMode: false
            },
            gridStore: null as Store|null,
            gridColumnStore: null as ColumnStore|null,
            userPreferences: {
                userFilters: new Array<any>(),
                userHiddenColumns: new Array<any>(),
                userColumnOrder: new Array<any>(),
                general: {
                    darkMode: false,
                    userStartPage: null,
                    userLocale: null,
                },
                refreshCurrentPage: true,
            },
            tmpPreferences: {
                tmpUserFilters: new Array<any>(),
                tmpUserHiddenColumns: new Array<any>(),
            },
            countAfterFilters: 0,
            token: "",
            user: null as UserInfo | null,
            oAuth:{
                routeAfterLogin: null as string|null
            },
            settings: {
                notifications: {
                    durationSuccess: 10000,
                    durationWarning: 10000,
                    durationError: -1,
                    durationInfo: 10000,
                },
                defaultHiddenColumns: null as Array<UserPreference>|null,
                systemCompanySettings: null as SystemCompanySettings|null,
                disdiSettings: null as DisdiSetting|null,
                activitySettings: null as ActivitySetting|null,
                startupConfig: null as StartupConfig|null,
                enabledFeatures: null as Array<string>|null,
            },
            notifications: new Array<HubNotification>(),
            uploadedFile: null,
        }

    },
    getters: {
        getNewNotificationCount: (state) => state.notifications.filter(x => x.isNew === true).length
    },
    actions: {
        async updateTablePreferences({ userId, tableName }:any) {
            const tmpUserFilters = this.tmpPreferences.tmpUserFilters;
            const filteredCurrentFilters = this.userPreferences.userFilters.filter((f:any) => f.tableName !== tableName);

            const tmpHiddenColumns = this.tmpPreferences.tmpUserHiddenColumns;
            const filteredCurrentHiddenColumns = this.userPreferences.userHiddenColumns.filter((f:any) => f.tableName !== tableName);

            // update preferences in store
            this.setUserPreferences({
                userFilters: tmpUserFilters.concat(filteredCurrentFilters),
                userHiddenColumns: tmpHiddenColumns.concat(filteredCurrentHiddenColumns),
                userColumnOrder: this.userPreferences.userColumnOrder,
                general: {
                    darkMode: this.userPreferences.general.darkMode,
                    userStartPage: this.userPreferences.general.userStartPage,
                    userLocale: this.userPreferences.general.userLocale,
                }
            });

            const userService = new UserService();

            // apply filters
            if(tmpUserFilters.length === 0) {
                this.gridStore?.removeFilter("userFilter");
            }
            else {
                this.gridStore?.filter({
                    id: "userFilter",
                    filterBy: FilterService.buildFilter(tableName)
                });
            }

            // apply column visibility
            // go through each column (visible or not)
            // @ts-expect-error
            for (const c of this.gridColumnStore?.storage.values) {
                const needsHiding = tmpHiddenColumns.some((thc:any) => thc.attributeName === c.data.field);

                if(needsHiding && !c.hidden) {
                    c.hide();
                }
                else if(!needsHiding && c.hidden && !permissionColumn.includes(c.id)) {
                    c.show();
                }
            }

            // update userPreferences in Backend / delete all if array is empty (bool param)
            const filterResp = await userService.setUserPreferences(userId, PreferenceType.filter, tableName, tmpUserFilters.length === 0, tmpUserFilters);
            if (filterResp === null || filterResp === undefined || filterResp.statusCode !== 0){
                ErrorHandler.handleError(filterResp, ErrorLocationType.popup);
                return;
            }

            const hiddenColResp = await userService.setUserPreferences(userId, PreferenceType.hiddenColumn, tableName, tmpHiddenColumns.length === 0, tmpHiddenColumns);
            if (hiddenColResp === null || hiddenColResp === undefined || hiddenColResp.statusCode !== 0){
                ErrorHandler.handleError(hiddenColResp, ErrorLocationType.popup);
                return;
            }

            // clear tmp data in store
            this.setTmpFilters([]);
            this.setTmpHiddenColumns([]);

            this.closeModal();
            this.showSuccess('Gespeichert');
        },
        resetState(){
            this.gridStore = new Store();
            this.gridColumnStore = null;
            this.notifications = new Array<HubNotification>();

            this.modal = {
                show: false,
                validator: { $validate:()=>{} },
                resetButton: false,
                title: "Default Popup Title",
                popupType: PopupType.none,
                contentObject: {},
                action: ()=>{},
                bigMode: false
            }

            this.userPreferences = {
                userFilters: new Array<any>(),
                userHiddenColumns: new Array<any>(),
                userColumnOrder: new Array<any>(),
                general: {
                    darkMode: false,
                    userStartPage: null,
                    userLocale: this.userPreferences.general.userLocale,
                },
                refreshCurrentPage: true,
            };

            this.tmpPreferences = {
                tmpUserFilters: new Array<any>(),
                tmpUserHiddenColumns: new Array<any>(),
            };
        },
        reorderColumns(tableName:string, customStore:ColumnStore|null = null){
            if(!this.userPreferences.userColumnOrder?.some(()=>true)) return;

            const orderedColumns = this.userPreferences.userColumnOrder?.filter(oc => oc.tableName === tableName);
            for (const oc of orderedColumns) {
                this.moveColumnToIndex(+oc.attributeValue, oc.attributeName, customStore);
            }
        },
        moveColumnToIndex(index:number, columnId:string, customStore:ColumnStore|null = null){
            if(NullOrUndefinedHelper(this.gridColumnStore) && NullOrUndefinedHelper(customStore)) return;

            const columnStore = (customStore ?? this.gridColumnStore) as ColumnStore;
            const elementToMove = columnStore.getById(columnId);

            // bryntum uses the index of the array AFTER removing the moved column from said array, so remove the column, before inserting it at the new index...
            // small reminder: OVERTHINKING IS HARD...
            columnStore.remove(elementToMove);
            columnStore.insert(index, elementToMove);
        },
        toggleDarkMode () {
            this.userPreferences.general.darkMode = !this.userPreferences.general.darkMode;
        },
        openPopup ({data, action, resetButton, title, type}:any, bigMode:boolean = false) {
            // close current popup or reset data to trigger new vue automaated loading and refreshing
            this.closeModal();

            this.modal.validator = {$validate: () =>true};
            this.modal.show = true;
            this.modal.title = title;
            this.modal.resetButton = resetButton;
            this.modal.popupType = type;
            this.modal.contentObject = data;
            this.modal.action = action;
            this.modal.bigMode = bigMode;
        },
        closeModal () {
            // dont reset action for specific popupTypes since they have a onUnmount-Workflow that needs to be triggered and needs the action to trigger no matter what:
            const resetedAction = this.modal.popupType === PopupType.registerPopup || this.modal.popupType === PopupType.locationCouplingPopup
                ? this.modal.action
                : ()=>{};
            this.modal = {
                show: false,
                validator: { $validate:()=>{} },
                resetButton: false,
                title: "Default Popup Title",
                popupType: PopupType.none,
                contentObject: {},
                action: resetedAction,
                bigMode: false
            }
        },
        showSuccess(text:string) {
            this.success.show = true;
            this.success.text = text === undefined ? "DEFAULT OK!" : text;
        },
        setGridStore (payload:Store|null) {
            this.gridStore = payload;
        },
        setGridColumnStore (payload:ColumnStore|null) {
            this.gridColumnStore = payload;
        },
        setUserPreferences (payload: { userFilters:any, userHiddenColumns:any, general: { darkMode:any, userStartPage:any, userLocale:any }, userColumnOrder:any }|null) {
            if (payload === null) return;
            this.userPreferences.userFilters = payload.userFilters;
            this.userPreferences.userHiddenColumns = payload.userHiddenColumns;
            this.userPreferences.general = payload.general;
            this.userPreferences.userColumnOrder = payload.userColumnOrder;
        },
        async saveUserColumnOrder (tableName:string, columnId:string, newIndex:string) {
            if(this.user === null) return;

            const colmnOrder = new AttributeFilter(tableName, columnId, newIndex);

            // save/update userPreferences in db
            const userService = new UserService();
            const saveResp = await userService.setUserPreferences(this.user.id, PreferenceType.columnOrder, tableName, false, [colmnOrder])
            if (saveResp === null || saveResp === undefined || saveResp.statusCode !== 0){
                ErrorHandler.handleError(saveResp, ErrorLocationType.table);

                return;
            }

            // update global store preferences
            const filteredList = this.userPreferences.userColumnOrder?.filter(oc => oc.attributeValue === columnId);
            filteredList.push(colmnOrder);
            this.userPreferences.userColumnOrder = filteredList;
        },
        setTmpFilters(payload: any|null) {
            this.tmpPreferences.tmpUserFilters = payload;
        },
        setTmpHiddenColumns(payload: any|null) {
            this.tmpPreferences.tmpUserHiddenColumns = payload;
        },
        addUserFilter(payload: any|null) {
            if (payload !== null) {
                this.tmpPreferences.tmpUserFilters.push(payload);
            }
        },
        addUserHiddenColumn(payload: any|null) {
            if (payload !== null) {
                this.tmpPreferences.tmpUserHiddenColumns.push(payload);
            }
        },
        setCountAfterFilters(payload: number) {
            this.countAfterFilters = payload;
        },
        setToken(payload:string){
            this.token = payload;
        },
        setUser(payload:any|null){
            this.user = payload;
        },
        getAuthorityUrl():string {
            if(router.currentRoute.value.name === "DisdiOtpLogin") return this.settings.startupConfig?.idsUrl ?? "";
            if(this.settings.startupConfig !== null && this.settings.startupConfig.ssoType !== SsoType.none) return this.settings.startupConfig.ssoUrl;
            return this.settings.startupConfig?.idsUrl ?? "";
        }
    }
})
