import { ref } from 'vue'
const state = ref({
    type: 'alert',
    active: false,
    message: '',
    title: '',
    hint:'',
    okText: 'Ok',
    cancelText: 'Abbrechen',
    inputType: 'text',
    html: false,
})


let close:any
const dialogPromise = () => new Promise((resolve) => (close = resolve))
const open = (message: any) => {
    state.value.message = message
    state.value.active = true
    return dialogPromise()
}
const reset = () => {
    state.value.active = false
    state.value.message = ''
    state.value.okText = 'Ok'
    state.value.cancelText = 'Cancel'
    state.value.inputType = 'text'
    state.value.html = false
    state.value.title = ''
    state.value.hint = ''
    state.value.type = 'alert'
}


const dialog = {
    get state() {
        return state
    },
    title(title: any) {
        state.value.title = title
        return this
    },
    hint(hint: string){
        state.value.hint = hint
        return this
    },
    okText(text: any) {
        state.value.okText = text
        return this
    },

    cancelText(text: any) {
        state.value.cancelText = text
        return this
    },
    inputType(type: any) {
        state.value.inputType = type
        return this
    },
    html(enabled = true) {
        state.value.html = enabled
        return this
    },
    alert(message: any) {
        state.value.type = 'alert'
        return open(message)
    },
    confirm(message: any) {
        state.value.type = 'confirm'
        return open(message)
    },
    prompt(message: any) {
        state.value.type = 'prompt'
        return open(message)
    },
    cancel() {
        close(false)
        reset()
    },
    ok(input = true) {
        input = state.value.type === 'prompt' ? input : true
        close(input)
        reset()
    },
}

export default dialog
