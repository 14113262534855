<template>
	<div v-if="dialog.state.active">
		<div class="dialog-inner">
			<h3 v-if="dialog.state.title">{{dialog.state.title}}</h3>

			<p v-if="dialog.state.html" v-html="dialog.state.message"></p>
			<p v-else-if="dialog.state.hint === ''">{{ dialog.state.message }}</p>
      <div v-else>
        <br>
        <p>{{ dialog.state.message.split(';')[0] }}</p>
        <p>{{ dialog.state.message.split(';')[1] }}</p>
      </div>

      <div v-if="dialog.state.hint !== ''">
        <br>
        <p>{{ dialog.state.hint }}</p>
      </div>

			<input v-if="dialog.state.type === 'prompt'" :type="dialog.state.inputType" v-model="userInput">

			<div class="promptButtons">
				<button class="mr-2" v-if="dialog.state.type !== 'alert'" @click="dialog.cancel()">{{dialog.state.cancelText}}</button>
				<button @click="dialog.ok(userInput)" class="primary">{{dialog.state.okText}}</button>
			</div>

		</div>
		<div class="dialog-bg" @click="dialog.cancel()"></div>
	</div>
</template>

<script>

import dialog from "@/dialog";

export default{
	data(){
		return {
			userInput: '',
			dialog
		}
	},
}
</script>

<style>
.dialog-bg{
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background:rgba(0,0,0,.5);
	z-index: 5000;
}
.dialog-inner{
	@apply rounded-md;
	background: #fff;
	padding:20px;
	position:fixed;
	top:200px;
	left: 50%;
	transform: translateX(-50%);
	min-width:200px;
	z-index: 5001;
}
.promptButtons {
	@apply mt-4;
}
</style>
