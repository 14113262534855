<template>
  <div>
    Waiting
  </div>
</template>
<script setup>
import {onMounted} from "vue";
import OAuthService from "@/utilities/services/OAuthService";
import {useGlobalStore} from "@/stores/GlobalStore";
import {disdiClientConfig} from "@/AppConfig";

const globalStore = useGlobalStore();
const oAuthService = new OAuthService(globalStore.getAuthorityUrl(), disdiClientConfig.clientId, disdiClientConfig.scopes, disdiClientConfig.callback, disdiClientConfig.silent); //TODO configure correctly

onMounted(async ()=> {
  try {
    await oAuthService.refreshTokensSilently();
  }
  catch (e) {
    console.error(e);
    return;
  }
})

</script>
