import {WebCallService} from "@/utilities/services/WebCallService";
import {PathHelper} from "@/utilities/helpers/PathHelper";
import {DisdiInfo} from "@/models/disdi/DisdiInfo";
import {ErrorLocationType, HttpType} from "@/utilities/enums/Enums";
import {
    AttachmentResponse, DiaryEntryAttachmentResponse,
    DiaryEntryResponse,
    DisdiResponse,
    ExportPDFResponse,
    GeneralResponse, GuidResponse
} from "@/models/api/Responses";
import ErrorHandler from "@/utilities/helpers/ErrorHandler";
import {CreateEmptyDisdiRequest, FileNameListRequest, UpdateDisdiEntryRequest} from "@/models/api/Requests";
import {BlobUploadService} from "@/utilities/services/BlobUploadService";
import {PdfFile} from "@/models/PdfFile";

export class DisdiService{
    private webCaller: WebCallService;
    private uriHelper: PathHelper;
    private blobUploader: BlobUploadService;

    constructor(){
        this.webCaller = new WebCallService();
        this.uriHelper = new PathHelper();
        this.blobUploader = new BlobUploadService();
    }

    public async getDisdiEntriesForRegistration(regId:number, errorLocation:ErrorLocationType):Promise<Array<DisdiInfo> | null>{
        const uri = this.uriHelper.disdi.getDisdiByRegistrationPath(regId);

        const resp = await this.webCaller.HttpSend(HttpType.get, uri) as DisdiResponse;
        if (resp === null || resp === undefined || resp.statusCode !== 0){
            ErrorHandler.handleError(resp as GeneralResponse, errorLocation);
            return null;
        }

        return resp.diaryEntries;
    }

    public async getDisdiEntriesForLocation(locationId:number, errorLocation:ErrorLocationType):Promise<Array<DisdiInfo> | null>{
        const uri = this.uriHelper.disdi.getDisdiByLocationPath(locationId);

        const resp = await this.webCaller.HttpSend(HttpType.get, uri) as DisdiResponse;
        if (resp === null || resp === undefined || resp.statusCode !== 0){
            ErrorHandler.handleError(resp as GeneralResponse, errorLocation);
            return null;
        }

        return resp.diaryEntries;
    }

    public async getAttachments(disdiId:string, errorLocation:ErrorLocationType):Promise<Array<string>|null>{
        const uri = this.uriHelper.disdi.getAttachmentsPath(disdiId);

        const resp = await this.webCaller.HttpSend(HttpType.get, uri) as AttachmentResponse;
        if (resp === null || resp === undefined || resp.statusCode !== 0){
            ErrorHandler.handleError(resp as GeneralResponse, errorLocation);
            return null;
        }

        return resp.diaryEntryAttachments;
    }

    public async checkAndUpdateHasAttachmentProperty(disdiId:string):Promise<GeneralResponse>{
        const uri = this.uriHelper.disdi.getCheckAndUpdateHasAttachmentPropertyPath(disdiId);

        return await this.webCaller.HttpSend(HttpType.put, uri) as GeneralResponse;
    }

    public async exportPdf(disdiIds: Array<string>, errorLocation:ErrorLocationType): Promise<boolean> {
        const uri = this.uriHelper.disdi.getDisdiPdfExportPath();
        const requestBody = disdiIds;

        const resp = await this.webCaller.HttpSend(HttpType.post, uri, requestBody) as ExportPDFResponse;
        if (resp === null || resp === undefined || resp.statusCode !== 0){
            ErrorHandler.handleError(resp as GeneralResponse, errorLocation);
            return false;
        }

        //@ts-expect-error
        const buffer = new Buffer(resp.pdfContentResult?.fileContents, "base64");
        const blob = new Blob([buffer], { type: resp.pdfContentResult?.contentType });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = resp.pdfContentResult?.fileDownloadName as string;
        link.click();
        link.remove();

        return true;
    }

    public async getUncompletedDisdiById(disdiId:string, errorLocation:ErrorLocationType):Promise<DisdiInfo|null>{
        const uri = this.uriHelper.disdi.getDisdiEntryByIdPath(disdiId);

        const resp = await this.webCaller.HttpSend(HttpType.get, uri) as DiaryEntryResponse;
        if (resp === null || resp === undefined || resp.statusCode !== 0){
            ErrorHandler.handleError(resp as GeneralResponse, errorLocation);
            return null;
        }

        return resp.diaryEntry;
    }

    public async getAttachementUploadUrl(disdiId:string, fileNames:Array<string>, errorLocation:ErrorLocationType):Promise<Array<string>|null>{
        const uri = this.uriHelper.disdi.getAttachmentUploadUrlPath(disdiId);
        const requestBody = new FileNameListRequest(fileNames);

        const resp = await this.webCaller.HttpSend(HttpType.put, uri, requestBody) as DiaryEntryAttachmentResponse;
        if (resp === null || resp === undefined || resp.statusCode !== 0){
            ErrorHandler.handleError(resp as GeneralResponse, errorLocation);
            return null;
        }

        return resp.diaryEntryAttachments;
    }

    public async deleteAttachmentByFileName(disdiId:string, fileName:string, errorLocation:ErrorLocationType):Promise<boolean>{
        const uri = this.uriHelper.disdi.getDeleteAttachmentFromBlobStoragePath(disdiId, fileName);

        const resp = await this.webCaller.HttpSend(HttpType.delete, uri) as GeneralResponse;
        if (resp === null || resp === undefined || resp.statusCode !== 0){
            ErrorHandler.handleError(resp as GeneralResponse, errorLocation);
            return false;
        }

        return true;
    }

    public async uploadFilesToBlobStorage(uploadUrls:Array<string>, files:Array<File>, errorLocation:ErrorLocationType, callback:Function|null = null):Promise<boolean>{
        let allSuccess = true;
        for(const file of files){
            const uploadUrl = uploadUrls.find(url => url.includes(encodeURIComponent(file.name))) as string;
            const successful = await this.blobUploader.UploadFile(file, uploadUrl, callback);
            allSuccess = !allSuccess ? false : successful;
        }

        if(!allSuccess){
            const resp = new GeneralResponse();
            resp.statusCode = 999999;
            resp.statusDescription = "Failed to ulpoad some pictures";
            ErrorHandler.handleError(resp, errorLocation);
        }

        return allSuccess;
    }

    public async updateDisdiEntry(entry:DisdiInfo, errorLocation:ErrorLocationType):Promise<boolean>{
        const uri = this.uriHelper.disdi.getDisdiEntryByIdPath(entry.id);
        const requestBody = new UpdateDisdiEntryRequest(entry);

        const resp = await this.webCaller.HttpSend(HttpType.put, uri, requestBody) as GeneralResponse;
        if (resp === null || resp === undefined || resp.statusCode !== 0){
            ErrorHandler.handleError(resp as GeneralResponse, errorLocation);
            return false;
        }

        return true;
    }

    public async createEmptyDisdiEntry(locSecId:number, regId:number, userId:string):Promise<GuidResponse>{
        const uri = this.uriHelper.disdi.getCreateEmtpyPath();
        const requestBody = new CreateEmptyDisdiRequest(locSecId, regId, userId);

        return await this.webCaller.HttpSend(HttpType.post, uri, requestBody);
    }
}