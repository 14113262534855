// replace dangerous starting characters and remove seperators from the cell-value
// see: https://owasp.org/www-community/attacks/CSV_Injection
import isNullOrUndefined from "@/utilities/helpers/NullOrUndefinedHelper";

export default (stringToSanitize:string, isPhonenumber:boolean = false):string => {
    // also recheck type since runtime JS is typedynmaic... :(
    if(isNullOrUndefined(stringToSanitize) || typeof(stringToSanitize) !== "string") return stringToSanitize;

    let result = "";
    result = stringToSanitize.replace(/^[=+\-@\n\r\t]+/g, "").replace(/[;,]/gm, "").replace(/['"]/gm, "");
    return `${isPhonenumber ? "+" : ""}${result}`;
}