import {LocationInfo} from "@/models/location/LocationInfo";

export class LocationDisdiInfo{
    id:number
    hasDisdi:boolean

    constructor(id:number = -1, disdi:boolean = false) {
        this.id = id;
        this.hasDisdi = disdi;
    }
}
