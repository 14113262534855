<template>
	<div>
		<div class="block w-full">

			<TabsWrapper :activeTab="activeTab">
				<TabItem :title="$t('locationsReports.tabs.history')" :hidden="showLocationReport">
          <div v-if="permissionService.userHasPermission('LocationReport.Navigation.Show')">
            <LocationReportsUserHistoryPopup/>
          </div>
				</TabItem>
				<TabItem :title="$t('locationsReports.tabs.locationDetails')">
					<div class="grid grid-cols-2 gap-4 w-full">
						<div>
              <div class="flex locationDetailRow">
                <div class="detailAttribute">{{ t('locationsReports.secondaryId') }}</div>
                <div>{{ popupData.secondaryId }}</div>
              </div>
							<div class="flex locationDetailRow">
								<div class="detailAttribute">{{ t('locationsReports.city') }}</div>
								<div>{{ popupData.contactInfo?.city ?? popupData.city }}</div>
							</div>
							<div class="flex locationDetailRow">
								<div class="detailAttribute">{{ t('locationsReports.street') }}</div>
								<div>{{ popupData.contactInfo?.street ?? popupData.street }} {{ popupData.contactInfo?.streetNumber ?? popupData.streetNumber }}</div>
							</div>
							<div class="flex locationDetailRow">
								<div class="detailAttribute">{{ t('locationsReports.zip') }}</div>
								<div>{{ popupData.contactInfo?.postalCode ?? popupData.postalCode }}</div>
							</div>
							<div class="flex locationDetailRow">
								<div class="detailAttribute">{{ t('locationsReports.phoneNumber') }}</div>
								<div>{{ popupData.contactInfo?.phoneNumber ?? popupData.phoneNumber }}</div>
							</div>
							<div class="flex locationDetailRow">
								<div class="detailAttribute">{{ t('locationsReports.email') }}</div>
								<div><a :href="`mailto:${popupData.contactInfo?.email ?? popupData.email}`">{{ popupData.contactInfo?.email ?? popupData.email }}</a></div>
							</div>
							<div class="flex locationDetailRow">
								<div class="detailAttribute">{{ t('locationsReports.gps') }}</div>
								<div v-if="popupData.latitude && popupData.longitude">
									{{ popupData.latitude }} | {{ popupData.longitude }}
								</div>
								<div v-else>
									{{ t('locationsReports.noGpsData') }}
								</div>
							</div>
						</div>
						<div>
							<div class="w-full h-64">
								<div v-if="popupData.latitude && popupData.longitude" class="w-full h-64">
									<l-map v-model="zoom" v-model:zoom="zoom" :center="[popupData.latitude, popupData.longitude]" @move="log('move')">
										<l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></l-tile-layer>
										<l-marker :lat-lng="[popupData.latitude, popupData.longitude]">
											<l-tooltip>
												{{ popupData.name }}
											</l-tooltip>
										</l-marker>
										<l-circle :lat-lng="[popupData.latitude, popupData.longitude]" :radius="popupData.registrationRadius*1000" color="#1796C1" />
									</l-map>
								</div>
								<div v-else>
									<img class="w-full h-64" src="@/assets/img/map_thumb.png" :alt="t('locationsReports.noGpsData')">
								</div>
							</div>
						</div>
					</div>
				</TabItem>
				<TabItem :title="$t('locationsReports.tabs.safetyInstructions')">
					<div class="grid grid-cols-2 gap-8 w-full" v-if="hasSafetyInstructions">
						<div class="securityNotification" v-for="si of popupData.safetyInstructionInfo" :key="si.id">
							<h3>{{si.name}}</h3>
							{{securityInstructionList.find(s => s.name === si.name)?.text}}
						</div>
					</div>
          <div v-else>
            {{t('locationsReports.tabs.safetyInstructionsEmpty')}}
          </div>
				</TabItem>
			</TabsWrapper>

		</div>
	</div>
</template>

<script setup>
import {computed, defineProps, onMounted, ref, toRef} from "vue";
import {useI18n} from "vue-i18n";
import ErrorHandler from "@/utilities/helpers/ErrorHandler";
import {ErrorLocationType} from "@/utilities/enums/Enums";
import {
	LMap,
	LTileLayer,
	LMarker,
	LTooltip,
	LCircle
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
import {SafetyInstructionService} from "@/utilities/services/entities/SafetyInstructionService";
import TabsWrapper from "@/components/tabs/TabsWrapper";
import TabItem from "@/components/tabs/TabItem";
import LocationReportsUserHistoryPopup from "@/components/popups/locationReports/LocationReportsUserHistoryPopup";
import {PermissionService} from "@/utilities/services/PermissionService";


let zoom = 15;
let activeTab = ref(0);

const { t } = useI18n();
const props = defineProps({
	data: Object
})

const popupData = toRef(props, 'data');
const securityInstructionList = ref([]);
const hasSafetyInstructions = computed(()=>{
  return popupData.value.safetyInstructionInfo !== undefined && popupData.value.safetyInstructionInfo.length > 0;
});

const safetyInstructionService = new SafetyInstructionService();

const permissionService = new PermissionService();
const showLocationReport = computed(() => {
  return !permissionService.userHasPermission("LocationReport.Navigation.Show");
})

onMounted(async ()=>{
  if(popupData.value.safetyInstructionInfo === undefined) popupData.value.safetyInstructionInfo = popupData.value.safetyInstructions;

  const safetyInstructionsResp = await safetyInstructionService.getSafetyInstructions("display");

  if (safetyInstructionsResp === null || safetyInstructionsResp === undefined || safetyInstructionsResp.statusCode !== 0) {
    ErrorHandler.handleError(safetyInstructionsResp, ErrorLocationType.popup);
    return;
  }

  securityInstructionList.value = safetyInstructionsResp.safetyInstructionInfo;
})

</script>

<style scoped>

</style>
