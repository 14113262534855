<template>
	<div>

		<div class="block w-full">
			<div class="grid grid-cols-1 gap-4 w-full">
				<div class="flex">
					<div class="pr-4 instantMessageWarning">
						<span class="icon-sicherheitshinweis"></span>
					</div>
					<div>
						{{ $t('locationsReports.instantMessageDescription') }}
					</div>
				</div>
				<div>
					<div class="inputTitle">
						{{ $t('locationsReports.instantMessage') }} *
					</div>
					<div class="inputField flex-grow" >
						<textarea class="form-control w-full" type="text" rows="4" v-model="popupData.message"></textarea> <br/>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script setup>
import {defineProps, onMounted, toRef} from "vue";
import ErrorHandler from "@/utilities/helpers/ErrorHandler";
import {ErrorLocationType} from "@/utilities/enums/Enums";
import {SettingService} from "@/utilities/services/entities/SettingService";

const props = defineProps({
	data: Object
})

const popupData = toRef(props, 'data');

const settingService = new SettingService();

onMounted(async ()=>{
  const instantMessageTextResp = await settingService.getDefaultInstantMessageText();
  if (instantMessageTextResp === null || instantMessageTextResp === undefined || instantMessageTextResp.statusCode !== 0){
    ErrorHandler.handleError(instantMessageTextResp, ErrorLocationType.popup);
    return;
  }

  popupData.value.message = instantMessageTextResp.text;
})

</script>

<style scoped>

</style>
