import { Guid } from "guid-typescript";
import { LocationInfo } from "@/models/location/LocationInfo";
import { ControlCenterInfo } from "@/models/controlCenter/ControlCenterInfo";
import { LevelInfo } from "@/models/group2/LevelInfo";
import { ContactInfo } from "@/models/ContactInfo";
import { RegionInfo } from "@/models/group1/RegionInfo";
import { SafetyInstructionInfo } from "@/models/safetyInstruction/SafetyInstructionInfo";
import { UserPreference } from "@/models/user/UserPreference";
import { UserInfo } from "@/models/user/UserInfo";
import isNullOrUndefined from "@/utilities/helpers/NullOrUndefinedHelper";
import {CompanyInfo} from "@/models/company/CompanyInfo";
import {CompanyWorkerInfo} from "@/models/company/CompanyWorkerInfo";
import {ActivitySetInfo} from "@/models/activity/ActivitySetInfo";
import {CoupledSystemSetting} from "@/models/system/CoupledSystemSetting";
import {PronunciationSetting} from "@/models/setting/PronunciationSetting";
import {CategoryInfo} from "@/models/category/CategoryInfo";
import {DisdiSetting} from "@/models/setting/disdi/DisdiSetting";
import {NameValueObject} from "@/models/NameValueObject";
import {DisdiMetadataType, DisdiStateType} from "@/utilities/enums/Enums";
import {ActivityInfo} from "@/models/activity/ActivityInfo";
import {LocationDisdiInfo} from "@/models/location/LocationDisdiInfo";
import {DisdiInfo} from "@/models/disdi/DisdiInfo";
import NullOrUndefinedHelper from "@/utilities/helpers/NullOrUndefinedHelper";


export class GeneralRequest {
    requestId: Guid = Guid.create();
}

export class LocationRequest extends GeneralRequest {
    id: number = 0;
    secondaryId: string = "";
    syncId?: string = undefined;
    longitude?: number = undefined;
    latitude?: number = undefined;
    registrationRadius?: number = undefined;
    name: string = "";
    namePronunciation: string = "";
    email: string = "";
    contactInfo: ContactInfo = new ContactInfo();
    phoneNumberAlt1?: string = undefined;
    phoneNumberAlt2?: string = undefined;
    phoneNumberAltIsFallback: boolean = false;
    controlCenterId?: number = undefined;
    couplingEnabled: boolean = false;
    customAdditionalInformation?: string = undefined;
    group1Id?: number = undefined;
    group2Id?: number = undefined;
    safetyInstructions: Array<{ id:number, displayOrder:number }> = [];
    pdfTemplate?: number = undefined;
    activitySetId: number|null = null;
    hasDisdi: boolean;

    constructor(location:LocationInfo) {
        super();
        this.id = location.id;
        this.secondaryId = location.secondaryId;
        this.syncId = location.syncId === "" ? undefined: location.syncId;
        this.longitude = NullOrUndefinedHelper(location.longitude) ? undefined : location.longitude;
        this.latitude = NullOrUndefinedHelper(location.latitude) ? undefined : location.latitude;
        this.registrationRadius = location.registrationRadius;
        this.name = location.name;
        this.namePronunciation = location.namePronunciation;
        this.email = location.email;
        this.contactInfo = location.contactInfo;
        this.phoneNumberAlt1 = location.phoneNumberAlt1;
        this.phoneNumberAlt2 = location.phoneNumberAlt2;
        this.phoneNumberAltIsFallback = location.phoneNumberAltIsFallback;
        this.controlCenterId = location.controlCenterId;
        this.couplingEnabled = location.couplingEnabled;
        this.customAdditionalInformation = location.customAdditionalInformation;
        this.group1Id = location.group1Id;
        this.group2Id = location.group2Id;
        this.safetyInstructions = location.safetyInstructions;
        this.pdfTemplate = location.pdfTemplate;
        this.activitySetId = location.activitySetId;
        this.hasDisdi = location.hasDisdi;
    }
}

export class ControlCenterRequest extends GeneralRequest {
    id:number = -1;
    name:string = "";
    phoneNumber:string = "";
    syncId?:string = undefined;

    constructor(center:ControlCenterInfo) {
        super();
        this.id = center.id;
        this.name = center.name;
        this.phoneNumber = center.phoneNumber;
        this.syncId = center.syncId;
    }
}

export class LevelRequest extends GeneralRequest {
    id:number = -1;
    name:string = "";

    constructor(level:LevelInfo) {
        super();
        this.id = level.id;
        this.name = level.name;
    }
}

export class RegionRequest extends GeneralRequest {
    id:number = -1;
    name:string = "";
    syncId:string = "";

    constructor(region:RegionInfo) {
        super();
        this.id = region.id;
        this.name = region.name;
        this.syncId = region.syncId;
    }
}

export class UserPreferenceRequest extends GeneralRequest {
    userId:string = "";
    preferenceTypeId:number = -1;
    tableName:string = "";
    clearAll:boolean = false;
    preferenceInfos:Array<UserPreference> = [];

    constructor(id:string, typeId:number, tableName:string, clearAll:boolean, prefList:Array<UserPreference>) {
        super();
        this.userId = id;
        this.preferenceTypeId = typeId;
        this.tableName = tableName;
        this.clearAll = clearAll;
        this.preferenceInfos = prefList;
    }
}

export class LoginRequest extends GeneralRequest {
    email:string = "";
    password:string = "";
    rememberMe: boolean = false;

    constructor(email: string, password: string, rememberMe: boolean)
    {
        super();
        this.email = email;
        this.password = password;
        this.rememberMe = rememberMe;
    }
}

export class ResetPasswordEmailRequest extends GeneralRequest {
    email:string = "";
    url:string = "";

    constructor(email:string, url:string){
        super();
        this.email = email;
        this.url = url;
    }
}

export class ResetPasswordRequest extends GeneralRequest {
    token:string;
    email:string;
    password:string;
    passwordRepetition:string;

    constructor(token:string, email:string, password:string, passwordRepetition:string){
        super();
        this.token = token;
        this.email = email;
        this.password = password;
        this.passwordRepetition = passwordRepetition;
    }
}

export class ChangePasswordRequest extends GeneralRequest {
    userId:string;
    oldPassword:string;
    newPassword:string;
    newPasswordRepetition:string;

    constructor(userId:string, oldPassword:string, newPassword:string, newPasswordRepetition:string){
        super();
        this.userId = userId;
        this.oldPassword = oldPassword;
        this.newPassword = newPassword;
        this.newPasswordRepetition = newPasswordRepetition;
    }
}

export class RefreshTokenRequest extends GeneralRequest {
    accessToken:string;

    constructor(accessToken:string){
        super();
        this.accessToken = accessToken;
    }
}

export class UserRequest extends GeneralRequest {
    id:string;
    firstName:string;
    lastName:string;
    identificationNumber:string;
    kid:string|null|undefined;
    email?:string;
    phoneNumber?:string;
    namePronunciation:string;
    roles:Array<string>;
    info:string;
    hasProtection:boolean;
    hasMultiAnAb:boolean;
    companyId?:string;

    constructor(user:UserInfo){
        super();
        this.id = user.id;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.identificationNumber = user.identificationNumber;
        this.kid = isNullOrUndefined(user.kid) ? undefined : user.kid;
        this.email = isNullOrUndefined(user.email) ? undefined : user.email;
        this.phoneNumber = isNullOrUndefined(user.phoneNumber) ? undefined : user.phoneNumber;
        this.namePronunciation = user.namePronunciation;
        this.roles = user.roles.map(r => r.name);
        this.info = user.info;
        this.hasProtection = user.hasProtection;
        this.hasMultiAnAb = false;
        this.companyId = user.companyId;
    }
}

export class VoiceTestRequest extends GeneralRequest {
    text: string;
    callNumber: string;

    constructor(text:string, callNumber:string){
        super();
        this.text = text;
        this.callNumber = callNumber;
    }
}

export class ManualRegistrationRequest extends GeneralRequest {
    userId:string = "";
    locationId:number = 0;
    activityText?:string = undefined;
    guestCount:number = 0;

    constructor(userId:string, locationId:number, activityText:string|undefined, guestCount:number) {
        super();
        this.userId = userId;
        this.locationId = locationId;
        this.activityText = activityText;
        this.guestCount = guestCount;
    }
}

export class SafetyInstructionRequest extends GeneralRequest {
    id:number = -1;
    name:string = "";
    text:string = "";
    locationIds:Array<number> = [];

    constructor(si:SafetyInstructionInfo) {
        super();
        this.id = si.id;
        this.name = si.name;
        this.text = si.text;
        this.locationIds = si.safetyInstructionLocations.map((l:LocationInfo) => l.id);
    }
}

export class ActivityInfoRequest extends GeneralRequest {
    activityId:number = -1;
    activityText:string = "";
    categoryId:string|null = null;

    constructor(activity:ActivityInfo) {
        super();
        this.activityId = activity.id ?? activity.activityId;
        this.activityText = activity.name ?? activity.activityText;
        this.categoryId = activity.categoryId;
    }
}

export class SendMessageRequest extends GeneralRequest {
    locationIds:Array<number> = [];
    message:string = "";

    constructor(ids:Array<number>, message:string) {
        super();
        this.locationIds = ids;
        this.message = message;
    }
}

export class CompanyRequest extends GeneralRequest {
    name:string = "";
    phone:string = "";
    phoneNumber:string = "";
    zip:string = "";
    city:string = "";
    street:string = "";
    streetnumber:string = "";
    dialCode:string = "";

    constructor(company:CompanyInfo) {
        super();
        this.name = company.name;
        this.phone = company.phoneNumber;
        this.phoneNumber = company.phoneNumber;
        this.zip = company.zip;
        this.city = company.city;
        this.street = company.street;
        this.streetnumber = company.streetnumber;
        this.dialCode = company.dialCode;
    }
}

export class ConnectSystemToCompanyRequest extends GeneralRequest {
    anAbSystemId?:string = undefined;
    companyId:string = "";
    companyIdCode:string = "";
    info:string = "";

    constructor(systemId:(string | undefined) = undefined, companyId:string, companyIdCode:string, info:string) {
        super();
        this.anAbSystemId = systemId;
        this.companyId = companyId;
        this.companyIdCode = companyIdCode;
        this.info = info;
    }
}

export class CompanyWorkerToAdminRequest extends GeneralRequest {
    companyId:string = "";
    companyIdCode:string = "";
    companyInfo:string = "";
    firstName:string = "";
    lastName:string = "";
    dialCode:string = "";
    phoneNumber:string = "";
    email:string = "";

    constructor(company:CompanyInfo, user:CompanyWorkerInfo){
        super();
        this.companyId = company.id;
        this.companyIdCode = company.companyIdCode;
        this.companyInfo = company.info;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.dialCode = user.dialCode;
        this.phoneNumber = user.phoneNumber;
        this.email = user.email;
    }
}

export class PhoneNumberRequest extends GeneralRequest {
    dialCode:string = "";
    phoneNumber:string = "";

    constructor(dialCode:string, phoneNumber:string) {
        super();
        this.dialCode = dialCode;
        this.phoneNumber = phoneNumber;
    }
}

export class CompanyWorkerRequest extends GeneralRequest {
    firstName:string = "";
    lastName:string = "";
    email:string = "";
    phoneNumber:string = "";
    dialCode:string = "";
    isEnabled:boolean = false;
    isAnonymous:boolean = false;
    expirationStatus:boolean = false;

    constructor(companyWorker:CompanyWorkerInfo) {
        super();
        this.firstName = companyWorker.firstName;
        this.lastName = companyWorker.lastName;
        this.email = companyWorker.email;
        this.phoneNumber = companyWorker.phoneNumber;
        this.dialCode = companyWorker.dialCode;
        this.isEnabled = companyWorker.isEnabled;
        this.isAnonymous = companyWorker.isAnonymous;
        this.expirationStatus = companyWorker.anAbSystem?.userConfirmationExpired ?? false;
    }
}

export class ActivitySetInfoRequest extends GeneralRequest {
    name:string = "";
    activityIds:Array<number> = [];
    addedLocationIds:Array<number> = [];
    removedLocationIds:Array<number> = [];

    constructor(activitySet:ActivitySetInfo, ids:{added:Array<number>, removed:Array<number>}) {
        super();
        this.name = activitySet.name;
        this.activityIds = activitySet.activities.map(a => a.id ?? a.activityId);
        this.addedLocationIds = ids.added;
        this.removedLocationIds = ids.removed;
    }
}

export class CoupledSystemRequest extends GeneralRequest {
    id:number = -1;
    name:string = "";
    baseUrl:string = "";
    loginName:string = "";
    password:string = "";
    contactName:string = "";
    contactEmail:string = "";
    contactPhoneNumber:string = "";

    constructor(cs:CoupledSystemSetting) {
        super();
        this.id = cs.id;
        this.name = cs.name;
        this.baseUrl = cs.baseUrl;
        this.loginName = cs.loginName;
        this.password = cs.password;
        this.contactName = cs.contactName;
        this.contactEmail = cs.contactEmail;
        this.contactPhoneNumber = cs.contactPhoneNumber;
    }
}

export class UpdatePronuncicationRequest extends GeneralRequest{
    name:string = "";
    value:string = "";

    constructor(ps:PronunciationSetting) {
        super();
        this.name = ps.name;
        this.value = ps.value;
    }
}

export class SystemSettingUpdateRequest extends GeneralRequest{
    value:string = "";

    constructor(ss:any) {
        super();
        this.value = ss.value;
    }
}

export class ChangeActivityRequest extends GeneralRequest{
    activityId :number = 0;
    activityText:string = "";

    constructor(id:number | undefined, text:string) {
        super();
        this.activityId = id ?? 0;
        this.activityText = text;
    }
}

export class CategoryRequest extends GeneralRequest{
    id :string = "";
    iconName:string = "";
    title:string = "";
    description:string = "";

    constructor(category:CategoryInfo) {
        super();
        this.id = category.id;
        this.iconName = category.iconName;
        this.title = category.title;
        this.description = category.description;
    }
}

export class UpdateDisdiSettingRequest extends GeneralRequest{
    disdi:string = "";
    permissionRead:Array<string> = [];
    permissionEdit:Array<string> = [];
    pictures:number = -1;
    metaTimestamp:string = "";
    metaEditor:boolean = false;
    metaInvisibleTime:number = -1;
    metaInvisible:number = -1;
    metaDeleteAll:number = -1;
    showInAppNr:number = -1;
    showInAppDays:number = -1;
    useGallery:boolean = false;

    constructor(disdiSetting:DisdiSetting) {
        super();
        this.disdi = DisdiStateType[disdiSetting.disdi];
        this.permissionRead = disdiSetting.permissionRead;
        this.permissionEdit = disdiSetting.permissionEdit;
        this.pictures = disdiSetting.pictures;
        this.metaTimestamp = DisdiMetadataType[disdiSetting.metaTimestamp];
        this.metaEditor = disdiSetting.metaEditor;
        this.metaInvisibleTime = disdiSetting.metaInvisibleTime;
        this.metaInvisible = disdiSetting.metaInvisible;
        this.metaDeleteAll = disdiSetting.metaDeleteAll;
        this.showInAppNr = disdiSetting.showInAppNr;
        this.showInAppDays = disdiSetting.showInAppDays;
        this.useGallery = disdiSetting.useGallery;
    }

    /*
        returns array with name-value object for backend...
     */
    public convertToRequestBody():Array<NameValueObject>{
        return Object.entries(this)
            .map((kvA)=> new NameValueObject(kvA[0], `${kvA[1]}`))
            .filter(kvA => kvA.name !== "requestId"); // remove requestId from requestBody since it is not part of the settings
    }
}

export class DisdiChangeRequest extends GeneralRequest{
    locationDisdiSettings:Array<LocationDisdiInfo> = [];

    constructor(changesList:Array<LocationDisdiInfo>) {
        super();
        this.locationDisdiSettings = changesList;
    }
}

export class FileNameListRequest extends GeneralRequest{
    fileNames:Array<string>;

    constructor(fileNamelist:Array<string>) {
        super();
        this.fileNames = fileNamelist;
    }
}

export class UpdateDisdiEntryRequest extends GeneralRequest{
    diaryDescription:string;
    comment:string;
    categoryId:string;
    isCompleted:boolean;

    constructor(entry:DisdiInfo) {
        super();
        this.diaryDescription = entry.diaryDescription;
        this.comment = entry.comment;
        this.categoryId = entry.categoryId;
        this.isCompleted = entry.isCompleted;
    }
}

export class CreateEmptyDisdiRequest extends GeneralRequest{
    locationSecondaryId:number;
    registrationId:number;
    userId:string;

    constructor(locSecId:number, regId:number, userId:string) {
        super();
        this.locationSecondaryId = locSecId;
        this.registrationId = regId;
        this.userId = userId;
    }
}
