import {WebCallService} from "@/utilities/services/WebCallService";
import {PathHelper} from "@/utilities/helpers/PathHelper";
import {ActivityResponse, GeneralResponse, GuidResponse, IdResponse} from "@/models/api/Responses";
import {ErrorLocationType, HttpType} from "@/utilities/enums/Enums";
import {ActivityInfo} from "@/models/activity/ActivityInfo";
import {ActivityInfoRequest} from "@/models/api/Requests";
import ErrorHandler from "@/utilities/helpers/ErrorHandler";

export class ActivityService {
    private webCaller: WebCallService;
    private uriHelper: PathHelper;

    constructor(){
        this.webCaller = new WebCallService();
        this.uriHelper = new PathHelper();
    }

    public async getActivitySetActivities(setId:number):Promise<ActivityResponse>{
        const uri = this.uriHelper.activities.getActivitySetActivitiesPath(setId);

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async getActivities(viewType:string = "display", errorLocation:ErrorLocationType):Promise<Array<ActivityInfo>|null>{
        const uri = this.uriHelper.activities.getActivitiesPath(viewType);

        const resp = await this.webCaller.HttpSend(HttpType.get, uri) as ActivityResponse;
        if (resp === null || resp === undefined || resp.statusCode !== 0){
            ErrorHandler.handleError(resp as GeneralResponse, errorLocation);
            return null;
        }

        return resp.activityInfo.map(a => {
            a.id = a.activityId;
            a.name = a.activityText;
            return a;
        });
    }

    /*
        returns boolean on update, and string/id when add
     */
    public async addOrUpdateActivity(activity:ActivityInfo, errorLocation:ErrorLocationType):Promise<boolean|number>{
        const isAdd = activity.id < 0;
        const uri = isAdd
            ? this.uriHelper.activities.getActivityPath()
            : this.uriHelper.activities.getSpecificActivityPath(activity.id);

        const requestBody = new ActivityInfoRequest(activity);

        const reqType = isAdd
            ? HttpType.post
            : HttpType.put;

        const resp = await this.webCaller.HttpSend(reqType, uri, requestBody) as GeneralResponse | IdResponse;
        if (resp === null || resp === undefined || resp.statusCode !== 0){
            ErrorHandler.handleError(resp as GeneralResponse, errorLocation);
            return isAdd
                ? -1
                : false;
        }

        return isAdd
            ? (<IdResponse>resp).id
            : true;
    }

    public async deleteActivity(id:number):Promise<GeneralResponse>{
        const uri = this.uriHelper.activities.getSpecificActivityPath(id);

        return await this.webCaller.HttpSend(HttpType.delete, uri);
    }
}