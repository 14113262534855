import {DisdiMetadataType, DisdiStateType} from "@/utilities/enums/Enums";
import {BaseDisdiSetting} from "@/models/setting/disdi/BaseDisdiSetting";

/*
    contains all settings for disdi in one object...
 */
export class DisdiSetting{
    disdi:DisdiStateType;
    permissionRead:Array<string>;
    permissionEdit:Array<string>;
    pictures:number;
    metaTimestamp:DisdiMetadataType;
    metaEditor:boolean;
    metaInvisibleTime:number
    metaInvisible:number;
    metaDeleteAll:number;
    showInAppNr:number;
    showInAppDays:number;
    useGallery:boolean;

    constructor(base?:BaseDisdiSetting) {
        this.disdi = base?.disdi ? DisdiStateType[base.disdi as keyof typeof DisdiStateType] : DisdiStateType.NoDISDI;
        this.permissionRead = base?.permissionRead ?? [];
        this.permissionEdit = base?.permissionEdit ?? [];
        this.pictures = base?.pictures ?? -1;
        this.metaTimestamp = base?.metaTimestamp ? DisdiMetadataType[base.metaTimestamp as keyof typeof DisdiMetadataType] : DisdiMetadataType.None;
        this.metaEditor = base?.metaEditor ?? false;
        this.metaInvisibleTime = base?.metaInvisibleTime ?? -1;
        this.metaInvisible = base?.metaInvisible ?? -1;
        this.metaDeleteAll = base?.metaDeleteAll ?? -1;
        this.showInAppNr = base?.showInAppNr ?? -1;
        this.showInAppDays = base?.showInAppDays ?? -1;
        this.useGallery = base?.useGallery ?? false;
    }
}