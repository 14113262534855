import {WebCallService} from "@/utilities/services/WebCallService";
import {PathHelper} from "@/utilities/helpers/PathHelper";
import {CompanySettingsResponse, DisdiSettingResponse, GeneralResponse, SettingsResponse, TextResponse} from "@/models/api/Responses";
import {ErrorLocationType, HttpType, ZdvSettingType} from "@/utilities/enums/Enums";
import {
    SystemSettingUpdateRequest,
    UpdateDisdiSettingRequest,
    UpdatePronuncicationRequest
} from "@/models/api/Requests";
import {PronunciationSetting} from "@/models/setting/PronunciationSetting";
import {DisdiSetting} from "@/models/setting/disdi/DisdiSetting";
import ErrorHandler from "@/utilities/helpers/ErrorHandler";
import {BaseDisdiSetting} from "@/models/setting/disdi/BaseDisdiSetting";
import NullOrUndefinedHelper from "@/utilities/helpers/NullOrUndefinedHelper";

export class SettingService {
    private webCaller: WebCallService;
    private uriHelper: PathHelper;

    constructor(){
        this.webCaller = new WebCallService();
        this.uriHelper = new PathHelper();
    }

    public async getSystemSettings():Promise<SettingsResponse>{
        const uri = this.uriHelper.settings.getGlobalSettingsPath();

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async putUpdateSystemSetting(ss:any):Promise<GeneralResponse>{
        const uri = this.uriHelper.settings.getGlobalSettingByIdPath(ss.id);
        const requestBody = new SystemSettingUpdateRequest(ss);

        return await this.webCaller.HttpSend(HttpType.put, uri, requestBody);
    }

    public async getClientSettings():Promise<any>{ //TODO type response ? still needed ?
        const uri = this.uriHelper.settings.getClientSettingsPath();

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async getPronunciationSettings():Promise<SettingsResponse>{
        const uri = this.uriHelper.settings.getPronunciationSettingsPath();

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async getDefaultActivityText():Promise<TextResponse>{
        const uri = this.uriHelper.settings.getDefaultActivityTextPath();

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async getDefaultInstantMessageText():Promise<TextResponse>{
        const uri = this.uriHelper.settings.getDefaultInstantMessageTextPath();

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async putUpdatePronunciationSetting(ps:PronunciationSetting):Promise<GeneralResponse>{
        const uri = this.uriHelper.settings.getPronunciationSettingsWithIdPath(ps.id);
        const requestBody = new UpdatePronuncicationRequest(ps);

        return await this.webCaller.HttpSend(HttpType.put, uri, requestBody);
    }

    public async getCompanySettings():Promise<CompanySettingsResponse>{
        const uri = this.uriHelper.settings.getCompanySettingsPath();

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async postUpdateDmaPersistance(daysAmount:number):Promise<GeneralResponse>{
        const uri = this.uriHelper.settings.getCompanyWorkerPersistancePath();
        const requestBody = `"${daysAmount}"`;

        return await this.webCaller.HttpSend(HttpType.post, uri, requestBody);
    }

    public async postUpdateExpiredText(text:string):Promise<GeneralResponse>{
        const uri = this.uriHelper.settings.getExpiredTextPath();
        const requestBody = `"${text.replace("\n", "\\n").replace("\r", "\\r").replace("\t", "\\t")}"`;

        return await this.webCaller.HttpSend(HttpType.post, uri, requestBody);
    }

    public async getDisdiSettings(errorLocation:ErrorLocationType):Promise<DisdiSetting|null>{
        const uri = this.uriHelper.settings.getDisdiSettingsPath();

        const disdiResp = await this.webCaller.HttpSend(HttpType.get, uri) as DisdiSettingResponse;
        if (disdiResp === null || disdiResp === undefined || disdiResp.statusCode !== 0) {
            ErrorHandler.handleError(disdiResp, errorLocation);
            return null;
        }

        return this.convertToDisdiSetting(disdiResp.disdiSetting?.disdiSetting);
    }

    public async updateDisdiSetting(setting:DisdiSetting, errorLocation:ErrorLocationType):Promise<boolean>{
        const uri = this.uriHelper.settings.getDisdiSettingByIdPath(ZdvSettingType.disdi);
        const requestBody = (new UpdateDisdiSettingRequest(setting)).convertToRequestBody();

        const updateResp = await this.webCaller.HttpSend(HttpType.put, uri, requestBody) as GeneralResponse;
        if (updateResp === null || updateResp === undefined || updateResp.statusCode !== 0) {
            ErrorHandler.handleError(updateResp, errorLocation);
            return false;
        }

        return true;
    }

    /*
        API returns string instead of enum-bytes, so to avoid magic string weirdness,
        the response gets converted into an 'enum-typed' forntend-viewmodel to make working with it easier
     */
    private convertToDisdiSetting(base:BaseDisdiSetting|null|undefined):DisdiSetting|null{
        if(NullOrUndefinedHelper(base)) return null;

        //@ts-expect-error
        return new DisdiSetting(base);
    }
}