import {WebCallService} from "@/utilities/services/WebCallService";
import {PathHelper} from "@/utilities/helpers/PathHelper";
import {
    CoupledSystemsResponse,
    CSVImportResponse,
    DetailedLocationResponse,
    ExportPDFResponse,
    GeneralResponse,
    LocationsResponse
} from "@/models/api/Responses";
import {ErrorLocationType, HttpType, PdfExportActionEnum} from "@/utilities/enums/Enums";
import {LocationInfo} from "@/models/location/LocationInfo";
import {DisdiChangeRequest, LocationRequest, SendMessageRequest} from "@/models/api/Requests";
import ErrorHandler from "@/utilities/helpers/ErrorHandler";
import {LocationDisdiInfo} from "@/models/location/LocationDisdiInfo";

export class LocationService {
    private webCaller: WebCallService;
    private uriHelper: PathHelper;

    constructor(){
        this.webCaller = new WebCallService();
        this.uriHelper = new PathHelper();
    }

    public async getLocations(viewtype: string = "display", errorLocation:ErrorLocationType): Promise<Array<LocationInfo>|null> {
        const uri = this.uriHelper.locations.getLocationsPath() + `/viewtype/${viewtype}`;

        const resp = await this.webCaller.HttpSend(HttpType.get, uri) as LocationsResponse;
        if (resp === null || resp === undefined || resp.statusCode !== 0){
            ErrorHandler.handleError(resp as GeneralResponse, errorLocation);
            return null;
        }

        return resp.locationInfo;
    }

    public async getLocation(id: number, errorLocation:ErrorLocationType): Promise<LocationInfo | null> {
        const uri = this.uriHelper.locations.getLocationPath(id);

        const resp = await this.webCaller.HttpSend(HttpType.get, uri) as DetailedLocationResponse;
        if (resp === null || resp === undefined || resp.statusCode !== 0){
            ErrorHandler.handleError(resp as GeneralResponse, errorLocation);
            return null;
        }

        return resp.locationInfo;
    }

    public async addOrUpdateLocation(location: LocationInfo): Promise<any> {
        const uri = location.id < 0
            ? this.uriHelper.locations.getLocationsPath()
            : this.uriHelper.locations.getLocationPath(location.id);

        const requestBody = new LocationRequest(location);

        // add displayOrder to SafetyInstructionList
        requestBody.safetyInstructions = requestBody.safetyInstructions.map((si: { id: number }, i) => {
            return {id: si.id, displayOrder: i};
        });

        const reqType = location.id < 0
            ? HttpType.post
            : HttpType.put;

        return await this.webCaller.HttpSend(reqType, uri, requestBody);
    }

    public async deleteLocation(id: number): Promise<GeneralResponse> {
        const uri = this.uriHelper.locations.getLocationPath(id);

        return await this.webCaller.HttpSend(HttpType.delete, uri);
    }

    public async postCoupleLocation(id: number, systemId: number, foreignLocationId: string): Promise<any> {
        const uri = this.uriHelper.locations.getLocationCouplingPath(id, systemId);

        return await this.webCaller.HttpSend(HttpType.post, uri, foreignLocationId);
    }

    public async deleteCoupling(id: number, systemId: number, foreignLocationId: string): Promise<any> {
        const uri = this.uriHelper.locations.getLocationCouplingPath(id, systemId);

        return await this.webCaller.HttpSend(HttpType.delete, uri, foreignLocationId);
    }

    public async getCoupledLocations(id: number): Promise<CoupledSystemsResponse> {
        const uri = this.uriHelper.locations.getCoupledLocationsPath(id);

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async getNextFreeLocationId(): Promise<GeneralResponse> {
        const uri = this.uriHelper.locations.getNextFreeLocationIdPath();

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async postImportLocations(importFile: File): Promise<CSVImportResponse> {
        const uri = this.uriHelper.locations.getLocationImportPath();
        const requestBody = new FormData();
        requestBody.append("formFile", importFile);

        return await this.webCaller.HttpSend(HttpType.post, uri, requestBody);
    }

    public async postExportLocationPdf(action: PdfExportActionEnum, locationIds: Array<number>): Promise<ExportPDFResponse> {
        const uri = this.uriHelper.locations.getLocationPdfExportPath(action);
        const requestBody = locationIds;

        return await this.webCaller.HttpSend(HttpType.post, uri, requestBody);
    }

    public async postSendMessageToRegisteredUsers(ids: Array<number>, message: string): Promise<GeneralResponse> {
        const uri = this.uriHelper.locations.getLocationNotifyPath();
        const requestBody = new SendMessageRequest(ids, message);

        return await this.webCaller.HttpSend(HttpType.post, uri, requestBody);
    }

    public async putSetLocationDisdiSettings(changeList:Array<LocationDisdiInfo>, errorLocation:ErrorLocationType):Promise<boolean>{
        const uri = this.uriHelper.locations.getChangeDisdiPath();
        const requestBody = new DisdiChangeRequest(changeList);

        const resp = await this.webCaller.HttpSend(HttpType.put, uri, requestBody) as GeneralResponse;
        if (resp === null || resp === undefined || resp.statusCode !== 0){
            ErrorHandler.handleError(resp, errorLocation);
            return false;
        }

        return true;
    }
}