<template>
  <div class="min-h-[240px]">
    <div v-show="!loading">
      <div class="block w-full">

        <div v-if="selectedCompany.hasAdmin" class="helpText">
          <b>{{ $t('popups.providerManagementDetails.companyHasAdmin') }}</b><br/>
          {{ selectedCompany.contactPersonName }}<br/>
          {{ selectedCompany.contactPersonPhoneNumber }}<br/>
          <a :href="'mailto:'+selectedCompany.contactPersonEmail">{{ selectedCompany.contactPersonEmail }}</a><br/>
        </div>

        <br/>

        <div class="groupTitle">
          {{ $t('popups.providerManagementDetails.workerData') }}
        </div>
        <div class="mb-4" v-if="popupData.isAdmin">
          <span class="adminBox">{{ $t('popups.providerManagementDetails.admin') }}</span>
        </div>

        <div class="grid grid-cols-2 gap-4 w-full mb-4">
          <div>
            <div class="inputTitle">
              {{ $t('popups.providerManagementDetails.firstName') }} *
            </div>
            <div class="inputField flex-grow" >
              <div :class="{ error: v$.firstName.$errors.length }">
                <input type="text" class="w-full" v-model="popupData.firstName" :disabled="selectedCompany.hasAdmin"/>
                <div class="input-errors" v-for="error of v$.firstName.$errors" :key="error.$uid">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="inputTitle">
              {{ $t('popups.providerManagementDetails.lastName') }} *
            </div>
            <div class="inputField flex-grow" >
              <div :class="{ error: v$.lastName.$errors.length }">
                <input type="text" class="w-full" v-model="popupData.lastName" :disabled="selectedCompany.hasAdmin"/>
                <div class="input-errors" v-for="error of v$.lastName.$errors" :key="error.$uid">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 gap-4 w-full mb-4">
          <div>
            <div class="inputTitle">
              {{ $t('popups.providerManagementDetails.email') }} (*)
            </div>
            <div class="inputField flex-grow" >
              <div :class="{ error: v$.email.$errors.length }">
                <input type="text" class="w-full" v-model="popupData.email" :disabled="selectedCompany.hasAdmin"/>
                <div class="input-errors" v-for="error of v$.email.$errors" :key="error.$uid">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-2 gap-4 w-full">
          <div>
            <div class="inputTitle">
              {{ $t('popups.providerManagementDetails.dialCode') }} *
            </div>
            <div class="inputField flex-grow" >
              <div :class="{ error: v$.dialCode.$errors.length }">
                <Select :options="countryDialCodes" class="w-full" mode="single"
                        :selected="countryDialCodes.find(cw => cw.dialCode===popupData.dialCode).id"
                        @selectChanged="(value)=>{popupData.dialCode = countryDialCodes.find(cw => cw.id===value).dialCode}"
                        :disabled="selectedCompany.hasAdmin"/>
                <div class="input-errors" v-for="error of v$.dialCode.$errors" :key="error.$uid">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="inputTitle">
              {{ $t('popups.providerManagementDetails.phoneNumber') }} (*)
            </div>
            <div class="inputField flex-grow" >
              <div :class="{ error: v$.phoneNumber.$errors.length }">
                <input type="text" v-model="popupData.phoneNumber" class="w-full" :disabled="selectedCompany.hasAdmin"/>
                <div class="input-errors" v-for="error of v$.phoneNumber.$errors" :key="error.$uid">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="my-4 staffSwitches">
            <div class="flex">
              <div>
                <input type="checkbox" class="switch" :checked="popupData.isEnabled" @change="changeIsEnabled"> {{ $t('popups.providerManagementDetails.enabling') }}
              </div>

            </div>
            <div class="mt-8 flex	items-center clear-both" >
						<button class="btn" :disabled="!canBeInvited()" @click="sendAdminInvitation" v-if="!selectedCompany.hasAdmin">
              {{ $t('popups.providerManagementDetails.inviteAsAdmin') }}
            </button>
						<div v-if="popupData.sendVerificationEmailDate !== null && popupData.sendVerificationEmailDate !== undefined">
							<span class="icon-check text-green ml-4"></span>
							<span class="icon-info text-blue ml-2" :title="$t('popups.providerManagementDetails.invitationInfo', {dateString: getDateString()})"></span>
						</div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div v-show="loading" class="text-center"><LoadingSpinner /></div>
  </div>
</template>

<script setup>
import {computed, defineProps, onMounted, ref, toRef} from "vue";
import {helpers, required, email, requiredIf} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import {useGlobalStore} from "@/stores/GlobalStore";
import {useI18n} from "vue-i18n";
import Select from "@/components/inputs/Select";
import {countryDialCodes} from "@/AppConfig";
import router from "@/router";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import NullOrUndefinedHelper from "@/utilities/helpers/NullOrUndefinedHelper";
import DateToStringHelper from "@/utilities/helpers/DateToStringHelper";
import ErrorHandler from "@/utilities/helpers/ErrorHandler";
import {ErrorLocationType} from "@/utilities/enums/Enums";
import {CompanyWorkerService} from "@/utilities/services/entities/CompanyWorkerService";
import {CompanyService} from "@/utilities/services/entities/CompanyService";
import {$t} from "@/utilities/i18n/config";

const globalStore = useGlobalStore();
const { t } = useI18n();
const companyWorkerService = new CompanyWorkerService();
const companyService = new CompanyService();

const props = defineProps({
  data: Object
})

const selectedCompany = ref({});

const popupData = toRef(props, 'data');

const getDateString = ()=>{
  return NullOrUndefinedHelper(popupData.value.sendVerificationEmailDate) ? "" : DateToStringHelper(new Date(popupData.value.sendVerificationEmailDate));
}
const canBeInvited = ()=>{
  return NullOrUndefinedHelper(popupData.value.sendVerificationEmailDate) && popupData.value.userId !== "" && !selectedCompany.value.hasAdmin;
}
const changeIsEnabled = ({target})=>{
  popupData.value.isEnabled = target.checked;
};

const sendAdminInvitation = async () => {
  const invitationResp = await companyWorkerService.postPromoteToCompanyAdmin(selectedCompany.value, popupData.value);
  if (invitationResp === null || invitationResp === undefined || invitationResp.statusCode !== 0) {
    ErrorHandler.handleError(invitationResp, ErrorLocationType.popup);
    return;
  }

  popupData.value.sendVerificationEmailDate = new Date().toJSON();

  // update grid data to prevent reopening of popup and been able to send AdminInvite again
  const gridEntry = globalStore.gridStore.records.find(r => r.id === popupData.value.id);
  gridEntry.data.sendVerificationEmailDate = popupData.value.sendVerificationEmailDate
  globalStore.gridStore.add(gridEntry.data);
};
const loading = ref(false);

onMounted(async () => {
  globalStore.modal.validator = v$;

  loading.value = true;
  // @ts-expect-error
  const selectedCompanyResp = await companyService.getCompanyById(router.currentRoute.value.params.id);
  // @ts-expect-error
  selectedCompany.value = selectedCompanyResp.companyInfo;

  // check if company has admin
  const hasAdminResp = await companyWorkerService.getCompanyHasUserWithRole(selectedCompany.value.id, "Admin");
  // if call fails silently fail... sad but true (\m/,) for the moment
  if(hasAdminResp === null || hasAdminResp === undefined || hasAdminResp.statusCode !== 0) return;

  selectedCompany.value.hasAdmin = hasAdminResp.isExist;

  loading.value = false;
})


// Validation
const rules = computed(() => {
  const rules = {
    firstName: { required: helpers.withMessage(t('validators.required'), required) },
    lastName: { required: helpers.withMessage(t('validators.required'), required) },
    phoneNumber: {
      requiredIf: helpers.withMessage(
          $t('validators.requiredIf'),
          requiredIf(popupData.value.email === '')
      ),
    },
    dialCode: { required: helpers.withMessage(t('validators.required'), required) },
    email: {
      requiredIf: helpers.withMessage(
          $t('validators.requiredIf'),
          requiredIf(popupData.value.phoneNumber === '')
      ),
			email
		},
  }
  return rules
})

const v$ = ref(useVuelidate(rules, popupData))
</script>

<style scoped>

</style>
