import {globalProps} from '@/main';
import {CoupledSafetyInstructionActionType, ErrorLocationType} from "@/utilities/enums/Enums";
import ErrorHandler from "@/utilities/helpers/ErrorHandler";
import {SafetyInstructionService} from "@/utilities/services/entities/SafetyInstructionService";

/*
    this class holds & offers all the backend functions that can be run by the frontend via SignalR
*/
class SignalRSender {
    public async actOnCoupledSecurityInstruction(id:number, decision:CoupledSafetyInstructionActionType){
        const connection = globalProps.signalRConnection;
        const safetyInstructionService = new SafetyInstructionService();

        let resp = undefined;
        switch (decision) {
            case CoupledSafetyInstructionActionType.accept:
                resp = await safetyInstructionService.AcceptCoupledSafetyInstruction(id);
                break;
            case CoupledSafetyInstructionActionType.decline:
                resp = await safetyInstructionService.deleteSafetyInstruction(id);
                break;
            default:
                return;
        }

        if(resp === null || resp === undefined || resp.statusCode !== 0){
            ErrorHandler.handleError(resp, ErrorLocationType.table);
            return;
        }

        console.log(`signalR is ${connection.state} with ${connection.baseUrl}`)
        connection.send("discardNotification", id);
    }

    public discardNotification(id:number){
        const connection = globalProps.signalRConnection;

        console.log(`signalR is ${connection.state} with ${connection.baseUrl}`)
        connection.send("discardNotification", id);
    }
}

export default new SignalRSender();