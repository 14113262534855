export class CoupledSystemSetting {
    id:number = -1;
    name:string = "";
    baseUrl:string = "";
    loginName:string = "";
    password:string = "";
    contactName:string = "";
    contactEmail:string = "";
    contactPhoneNumber:string = "";
    // only used for dynamic test column in view
    testCoupling:boolean = false;
}