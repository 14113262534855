<template>
	<div>

		<div class="block w-full text-grey">
			<h4>{{ $t("popups.settings.coupledSystems.resetPasswordHeadline") }}</h4>
			<br/>
			{{ $t("popups.settings.coupledSystems.resetPasswordText") }}
		</div>

	</div>
</template>

<script setup>

</script>

<style scoped>

</style>
