<template>
	<div class="h-60">

		<div class="block w-full">
			<div class="groupTitle">
        {{ $t('popups.providerManagement.gsmSearch') }}
      </div>
			<div class="grid grid-cols-2 gap-4 w-full">
				<div>
					<div class="inputTitle">
						{{ $t('popups.providerManagementDetails.dialCode') }} *
					</div>
					<div class="inputField flex-grow" >
						<div :class="{ error: v$.dialCode.$errors.length }">
							<Select :options="countryDialCodes" class="w-full" mode="single" @selectChanged="(value)=>{popupData.dialCode = countryDialCodes.find(cc => cc.id === value)?.dialCode}" />
							<div class="input-errors" v-for="error of v$.dialCode.$errors" :key="error.$uid">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="inputTitle">
						{{ $t('popups.providerManagementDetails.phoneNumber') }} *
					</div>
					<div class="inputField flex-grow" >
						<div :class="{ error: v$.phoneNumber.$errors.length }">
							<input type="text" v-model="popupData.phoneNumber" class="w-full" :placeholder="phoneNumberPlaceholder.replace('+49','')"/><br/>
							<div class="input-errors" v-for="error of v$.phoneNumber.$errors" :key="error.$uid">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
        <div class="contextError errorType rounded mt-4 text-sm" v-if="popupData.userInfo !== ''">
          {{popupData.userInfo}}
        </div>
			</div>
		</div>

	</div>
</template>

<script setup>
import {computed, defineProps, onMounted, ref, toRef} from "vue";
import {helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import {useGlobalStore} from "@/stores/GlobalStore";
import {useI18n} from "vue-i18n";
import Select from "@/components/inputs/Select";
import {countryDialCodes} from "@/AppConfig";
import {phoneNumberPlaceholder} from "@/AppConfig";


const globalStore = useGlobalStore();
const { t } = useI18n();

const props = defineProps({
	data: Object
})

const popupData = toRef(props, 'data');

onMounted(async () => {
	globalStore.modal.validator = v$;
})


// Validation

const rules = computed(() => {
	const rules = {
    dialCode: { required: helpers.withMessage(t('validators.required'), required) },
		phoneNumber: { required: helpers.withMessage(t('validators.required'), required) },
	}
	return rules
})

const v$ = ref(useVuelidate(rules, popupData))
</script>

<style scoped>

</style>
