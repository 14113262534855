<template>
	<div class="tabs__content" v-if="selectedTitle === props.title">
		<slot />
	</div>
</template>

<script setup>
import { inject, defineProps  } from 'vue'
const props = defineProps({
	title: String
})
const selectedTitle = inject('selectedTitle')
</script>
