<template>
	<div>
		<div class="contentHeader flex">
			<h4>{{ $t('settings.title') }}</h4>
		</div>
		<div class="contentBox">
			<TabsWrapper :activeTab="activeTab">
				<TabItem :title="$t('settings.tabs.systemSettings')">
					<SystemSettings/>
				</TabItem>
				<TabItem :title="$t('settings.tabs.speachTexts')">
					<SpokenText/>
				</TabItem>
				<TabItem :title="$t('settings.tabs.coupledSystems')">
					<CoupledSystems/>
				</TabItem>
				<TabItem :title="$t('settings.tabs.providerSettings')">
					<ProviderSettings/>
				</TabItem>
        <TabItem :title="$t('settings.tabs.disdiSettings')" :hidden="disdiFeatureDisabled">
          <DisdiSettingsTab/>
        </TabItem>
			</TabsWrapper>
		</div>
	</div>
</template>


<script setup>
import { useGlobalStore } from '@/stores/GlobalStore'
import TabsWrapper from "@/components/tabs/TabsWrapper.vue";
import TabItem from "@/components/tabs/TabItem.vue";
import {computed, onBeforeUnmount, ref} from "vue";
import SystemSettings from "@/components/tabs/settings/SystemSettingsTab";
import SpokenText from "@/components/tabs/settings/SpokenTextTab";
import ProviderSettings from "@/components/tabs/settings/ProviderSettingsTab";
import CoupledSystems from "@/components/tabs/settings/CoupledSystemsTab";
import DisdiSettingsTab from "@/components/tabs/settings/DisdiSettingsTab";
let activeTab = ref(0);
const globalStore = useGlobalStore();

const disdiFeatureDisabled = computed(() => {
  return !globalStore.settings.enabledFeatures.includes("ATB");
})

onBeforeUnmount(()=>{
	globalStore.currentItems = 0;
	globalStore.allItems = 0;
});

</script>

<style scoped>

</style>
