import {defineStore} from "pinia";
import {$t} from "@/utilities/i18n/config";
import {ErrorLocationType, PopupType} from "@/utilities/enums/Enums";
import {useGlobalStore} from "@/stores/GlobalStore";
import {CompanyInfo} from "@/models/company/CompanyInfo";
import NullOrUndefinedHelper from "@/utilities/helpers/NullOrUndefinedHelper";
import ErrorHandler from "@/utilities/helpers/ErrorHandler";
import {GeneralResponse} from "@/models/api/Responses";
import {CompanyService} from "@/utilities/services/entities/CompanyService";

// this store exists because we have a backToEdit button in 'SimilarCompanies'-Popup and add or update action is not shared...
export const useProviderStore = defineStore('ProviderStore', {
    state:()=>{
        return {
            companyService: new CompanyService()
        }
    },
    actions: {
        async addOrUpdateProvider() {
            const globalStore = useGlobalStore();

            // @ts-expect-error
            const company = globalStore.modal.contentObject.selectedProviderCompany === undefined
                ? globalStore.modal.contentObject
                // @ts-expect-error
                : globalStore.modal.contentObject.selectedProviderCompany ;

            // check if there are similar companies and offer them for selection
            if(company.id === ""){
                const similarCompaniesResp = await this.companyService.postGetAllSimilarCompany(company);
                // if call fails silently fail... sad but true (\m/,) for the moment
                if(similarCompaniesResp === null || similarCompaniesResp === undefined || similarCompaniesResp.statusCode !== 0) return;

                if(similarCompaniesResp.companiesInfo.some(()=>true)){
                    // @ts-expect-error
                    similarCompaniesResp.companiesInfo = similarCompaniesResp.companiesInfo.map(sc => { sc["id"] = sc.companyId; return sc; });

                    company.id = "null";
                    globalStore.openPopup({
                        data: {"suggestedProviderCompany":company, "similarCompanies":similarCompaniesResp.companiesInfo, "selectedProviderCompany":company},
                        action: this.letEnterCompanyIdCodeIfNeeded,
                        resetButton: false,
                        title:$t("popups.providerManagement.similarCompanies"),
                        type:PopupType.selectSimilarCompany
                    });
                    return;
                }
            }

            await this.tryCreateAndConnectCompany(company);
        },

        async letEnterCompanyIdCodeIfNeeded(){
            const globalStore = useGlobalStore();

            // @ts-expect-error
            const company = globalStore.modal.contentObject.selectedProviderCompany as CompanyInfo;

            // let enter CompanyIdCode if needed
            if(!NullOrUndefinedHelper(company.id) && (company.companyIdCode === undefined || company.companyIdCode === "")){
                globalStore.openPopup({
                    data: company,
                    action: this.addOrUpdateProvider,
                    resetButton: false,
                    title:$t("popups.providerManagement.new"),
                    type:PopupType.providerManagementEditPopup
                });

                return;
            }

            await this.addOrUpdateProvider();
        },

        async tryCreateAndConnectCompany(company:CompanyInfo) {
            const globalStore = useGlobalStore();

            const companyCreateResp = await this.companyService.addOrUpdateCompany(company);
            if (companyCreateResp === null || companyCreateResp === undefined || companyCreateResp.statusCode !== 0){
                ErrorHandler.handleError(companyCreateResp as GeneralResponse, ErrorLocationType.popup);
                return;
            }

            // when company is new create companyEnity first
            // CAUTION: sadly there are 2 cases here: 1) similar companies found && still create -> id === "null" 2) no similar companyies found -> id === ""
            if(NullOrUndefinedHelper(company.id)){
                // @ts-expect-error
                company.id = companyCreateResp.companyId;
                // @ts-expect-error
                company.companyId = company.id;
            }


            // connect companyEntity with current system (cross table entry AnAbSystemCompany)
            const connectResp = await this.companyService.postConnectSystemToCompany(undefined, company.id, company.companyIdCode, company.info);
            if (connectResp === null || connectResp === undefined || connectResp.statusCode !== 0){
                ErrorHandler.handleError(connectResp as GeneralResponse, ErrorLocationType.popup);

                await this.companyService.deleteCompany(company.id); // fire and forget call

                company.id = "null";
                // @ts-expect-error
                delete company.companyId;

                return;
            }

            // @ts-expect-error
            company.address = `${company.street} ${company.streetnumber}, ${company.zip} ${company.city}`;

            globalStore.gridStore?.add(company);
            globalStore.gridStore?.sort(globalStore.gridStore.sorters);

            globalStore.closeModal();
            globalStore.showSuccess($t('global.saved'));
        }
    }

})