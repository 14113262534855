import isNullOrUndefined from "@/utilities/helpers/NullOrUndefinedHelper";
import {DisdiInfo} from "@/models/disdi/DisdiInfo";
import sanatizeCsv from "@/utilities/helpers/CsvSanitizer";

export class LocationCsvExport {
    SecondaryId: string;
    SyncId: string;
    Name: string;
    NamePronunciation: string;
    PhoneNumber: string;
    PhoneNumberAlt1?: string;
    PhoneNumberAlt2?: string;
    PhoneNumberAltIsFallback: boolean;
    PostalCode: string;
    City: string;
    Street: string;
    StreetNumber: string;
    Longitude?: number;
    Latitude?: number;
    RegistrationRadius?: number;
    Email: string;
    Group1Id?: number;
    Group1SyncId: string;
    Group2Id?: number;
    ControlCenterId?: number;
    ControlCenterSyncId: string;
    CustomAdditionalInformation?: string; //technischer platz


    // replaces undefined or null with empty string for string-required properties, since the Import can't do that easily
    constructor (fullObj:any){
        this.SecondaryId = isNullOrUndefined(fullObj.secondaryId) ? "" : sanatizeCsv(fullObj.secondaryId);
        this.SyncId = isNullOrUndefined(fullObj.syncId) ? "" : sanatizeCsv(fullObj.syncId);
        this.Name = isNullOrUndefined(fullObj.name) ? "" : sanatizeCsv(fullObj.name);
        this.NamePronunciation = isNullOrUndefined(fullObj.namePronunciation) ? "" : sanatizeCsv(fullObj.namePronunciation);
        this.PhoneNumber = isNullOrUndefined(fullObj.phoneNumber) ? "" : sanatizeCsv(fullObj.phoneNumber, true);
        this.PhoneNumberAlt1 = isNullOrUndefined(fullObj.phoneNumberAlt1) ? "" : sanatizeCsv(fullObj.phoneNumberAlt1, true);
        this.PhoneNumberAlt2 = isNullOrUndefined(fullObj.phoneNumberAlt2) ? "" : sanatizeCsv(fullObj.phoneNumberAlt2, true);
        this.PhoneNumberAltIsFallback = fullObj.phoneNumberAltIsFallback;
        this.PostalCode = isNullOrUndefined(fullObj.postalCode) ? "" : sanatizeCsv(fullObj.postalCode);
        this.City = isNullOrUndefined(fullObj.city) ? "" : sanatizeCsv(fullObj.city);
        this.Street = isNullOrUndefined(fullObj.street) ? "" : sanatizeCsv(fullObj.street);
        this.StreetNumber = isNullOrUndefined(fullObj.streetNumber) ? "" : sanatizeCsv(fullObj.streetNumber);
        this.Longitude = isNullOrUndefined(fullObj.longitude) ? undefined : fullObj.longitude;
        this.Latitude = isNullOrUndefined(fullObj.latitude) ? undefined : fullObj.latitude;
        this.RegistrationRadius = isNullOrUndefined(fullObj.registrationRadius) ? undefined : fullObj.registrationRadius;
        this.Email = isNullOrUndefined(fullObj.email) ? "" : sanatizeCsv(fullObj.email);
        this.Group1Id = isNullOrUndefined(fullObj.group1Id) ? undefined : fullObj.group1Id;
        this.Group1SyncId = isNullOrUndefined(fullObj.group1SyncId) ? "" : sanatizeCsv(fullObj.group1SyncId);
        this.Group2Id = isNullOrUndefined(fullObj.group2Id) ? undefined : fullObj.group2Id;
        this.ControlCenterId = isNullOrUndefined(fullObj.controlCenterId) ? undefined : fullObj.controlCenterId;
        this.ControlCenterSyncId = isNullOrUndefined(fullObj.controlCenterSyncId) ? "" : sanatizeCsv(fullObj.controlCenterSyncId);
        this.CustomAdditionalInformation = isNullOrUndefined(fullObj.customAdditionalInformation) ? "" : sanatizeCsv(fullObj.customAdditionalInformation);
    }
}

export class UserCsvExport {
    FirstName: string;
    LastName: string;
    NamePronunciation: string;
    IdentificationNumber: string;
    Kid: string;
    Email: string;
    PhoneNumber: string;
    CompanyId?:string;
    Roles: Array<string>;
    IsActive: boolean;
    HasProtection: boolean;


    constructor (fullObj:any){
        this.FirstName = sanatizeCsv(fullObj.firstName);
        this.LastName = sanatizeCsv(fullObj.lastName);
        this.NamePronunciation = sanatizeCsv(fullObj.namePronunciation);
        this.IdentificationNumber = sanatizeCsv(fullObj.identificationNumber);
        this.Kid = sanatizeCsv(fullObj.kid);
        this.Email = sanatizeCsv(fullObj.email);
        this.PhoneNumber = sanatizeCsv(fullObj.phoneNumber, true);
        this.CompanyId = sanatizeCsv(fullObj.companyId);
        this.Roles = fullObj.roles.map((r:string) => sanatizeCsv(r));
        this.IsActive = fullObj.isActive;
        this.HasProtection = fullObj.hasProtection;
    }
}

export class SafetyinstructionCsvExport {
    Name: string;
    Text: string;
    Locations: string;

    constructor (fullObj:any){
        this.Name = sanatizeCsv(fullObj.name);
        this.Text = sanatizeCsv(fullObj.text);
        this.Locations = fullObj.safetyInstructionLocations.map((l:any) => l.secondaryId).join(" | ");
    }
}

export class DisdiCsvExport{
    locationSecondaryId:number = -1;
    registrationId:number = -1;
    userFullname:string = "";
    userPhoneNumber:string = "";
    userEmail:string = "";
    identificationNumber:string = "";
    kid:string = "";
    categoryId:string = "";
    diaryDescription:string = "";
    hasAttachment:string = "";
    comment:string = "";

    constructor (fullObj:DisdiInfo){
        this.locationSecondaryId = fullObj.locationSecondaryId;
        this.registrationId = fullObj.registrationId;
        this.categoryId = sanatizeCsv(fullObj.categoryId);
        this.diaryDescription = sanatizeCsv(fullObj.diaryDescription);
        this.hasAttachment = fullObj.hasAttachment.toString();
        this.comment = sanatizeCsv(fullObj.comment);
        this.userFullname = sanatizeCsv(fullObj.userFullname);
        this.userPhoneNumber = sanatizeCsv(fullObj.userPhoneNumber, true);
        this.userEmail = sanatizeCsv(fullObj.userEmail);
        this.identificationNumber = sanatizeCsv(fullObj.identificationNumber);
        this.kid = sanatizeCsv(fullObj.kid);
    }
}
