import { Guid } from "guid-typescript";
import { PdfExportActionEnum } from "@/utilities/enums/Enums";

export class PathHelper {
    locations: LocationPathHelper = new LocationPathHelper();
    safetyInstructions: SafetyInstructionPathHelper = new SafetyInstructionPathHelper();
    regions: RegionPathHelper = new RegionPathHelper();
    levels: LevelPathHelper = new LevelPathHelper();
    controlCenters: ControlCenterPathHelper = new ControlCenterPathHelper();
    misc: MiscPathHelper = new MiscPathHelper();
    coupledSystems:CoupledSystemPathHelper = new CoupledSystemPathHelper();
    registrations:RegistrationPathHelper = new RegistrationPathHelper();
    preferences:UserPreferencePathHelper = new UserPreferencePathHelper();
    account: AccountPathHelper = new AccountPathHelper();
    users: UserPathHelper = new UserPathHelper();
    company: CompanyPathHelper = new CompanyPathHelper();
    activities: ActivitiesPathHelper = new ActivitiesPathHelper();
    activitySets: ActivitySetsPathHelper = new ActivitySetsPathHelper();
    settings: SettingsPathHelper = new SettingsPathHelper();
    category: CategoryPathHelper = new CategoryPathHelper();
    disdi: DisdiPathHelper = new DisdiPathHelper();
}

class ActivitiesPathHelper  {
    getActivitySetActivitiesPath(setId:number):string {
        return `activities/activitySet/${setId}`;
    }
    getActivityPath():string {
        return `activities`;
    }
    getActivitiesPath(viewType:string):string {
        return `activities/viewType/${viewType}`;
    }
    getSpecificActivityPath(id:number):string {
        return `activities/${id}`;
    }
}

class ActivitySetsPathHelper  {
    getActivitySetsPath(viewType:string):string {
        return `activitieSets/viewType/${viewType}`;
    }
    getActivitySetBasePath():string {
        return `activitieSets`;
    }
    getSpecificActivitySetPath(id:number):string {
        return `activitieSets/${id}`;
    }
}

class CoupledSystemPathHelper {
    getCoupledSystemsPath():string {
        return `coupledsystems`;
    }
    getCoupledSystemByIdPath(id:number):string {
        return `coupledsystems/${id}`;
    }
    getAvailableLocationsForForeignSystemPath(systemId:number):string {
        return `coupledsystems/foreignLocation/callAPI/${systemId}`;
    }
}

class ControlCenterPathHelper {
    getControlCentersPath():string {
        return `controlCenters`;
    }
    getControlCenterPath(id:number) {
        return `controlCenters/${id}`;
    }
}

class MiscPathHelper {
    getPdfTemplatesPath():string {
        return `pdf/templates/locations`;
    }
    getVoiceTestCallPath():string {
        return `twilios/voice`;
    }
    getStartupConfigPath(){
        return `startups`;
    }
    getHubNotificationsPath(){
        return `hubnotifs`;
    }
    getChangelogPath(){
        return `logs`;
    }
}

class SafetyInstructionPathHelper {
    getSafetyInstructionsPath():string {
        return `safetyInstructions`;
    }

    getSafetyInstructionPath(id:number):string {
        return `safetyInstructions/${id}`;
    }

    getAcceptCoupledSafetyInstructionPath(id:number):string {
        return `safetyInstructions/${id}/coupling/accept`;
    }
}

class RegionPathHelper {
    getRegionsPath():string {
        return `regions`;
    }
    getRegionPath(id:number):string {
        return `regions/${id}`;
    }
}

class LevelPathHelper {
    getLevelsPath():string {
        return `levels`;
    }
    getLevelPath(id:number) {
        return `levels/${id}`;
    }
}

class LocationPathHelper {
    getNextFreeLocationIdPath():string {
        return `locations/nextId`;
    }
    getLocationsPath():string {
        return `locations`;
    }
    getLocationPath(id:number):string {
        return `locations/${id}`;
    }
    getLocationNotifyPath():string {
        return `locations/message`;
    }
    getLocationPdfExportPath(action:PdfExportActionEnum):string {
        return `locations/export/pdf/${PdfExportActionEnum[action]}`;
    }
    getLocationImportPath():string {
        return `locations/import`;
    }
    getLocationCouplingPath(id:number, systemId:number):string {
        return `locations/${id}/couple/${systemId}`;
    }
    getCoupledLocationsPath(id:number):string {
        return `locations/coupled/${id}`;
    }
    getChangeDisdiPath(){
        return `locations/changeDisdi`
    }
}

class RegistrationPathHelper {
    getManualRegistrationPath() {
        return `registrations/manual`;
    }
    getOpenRegistrationsByUsersPath() {
        return `registrations/open/users`;
    }
    getRegistrationReportsPath(){
        return `registrations/all`;
    }
    getLocationUserReportsPath(id:number):string {
        return `locations/${id}/users`;
    }
    getLocationReportsPath(){
        return `locations/registrations/all`;
    }
    getChangeActivityForRegistrationPath(regId:number){
        return `registrations/activity/${regId}`;
    }
    getOpenRegistrationForUserPath(userId:string){
        return `registrations/users/${userId}/open`;
    }
}

class UserPreferencePathHelper {
    getUserPreferencePath(id:string, typeId:number){
        return `users/${id}/preference/${typeId}`;
    }
    getSaveUserPreferencesPath(){
        return `users/preference`;
    }
}

class UserPathHelper {
    getUsersPath():string {
        return `users`;
    }
    getUsersRoleSelectablePath():string{
        return `users/rolle/viewType/selectable`;
    }
    getUserByIdPath(id:string){
        return `users/${id}`;
    }
    getUserLocationPermissionPath(id:string, auth:boolean | any = undefined){
        return auth !== undefined
            ? `users/${id}/locationpermissions/${auth}`
            : `users/${id}/locationpermissions`;
    }
    getResetUserActivityPath(){
        return `users/resetactivity`;
    }
    getNextFreeUserIdPath(){
        return `users/nextId`;
    }
}

class AccountPathHelper{
    getLoginPath(){
        return `account`;
    }
    getResetPasswordMailPath(){
        return `account/resetPassword/email`;
    }
    getResetPasswordPath(){
        return `account/resetPassword`;
    }
    getChangePasswordPath(){
        return `account/changePassword`;
    }
    getLogoutPath(){
        return `account/logout`;
    }
    getRefreshTokenPath(){
        return `account/refresh`;
    }
    getExchangeTokenPath(){
        return `account/exchange`;
    }
}

class CompanyPathHelper {
    getCompanyPath(){
        return `providers`;
    }
    getProviderByIdPath(id:string){
        return `providers/company/${id}`;
    }
    putProviderByIdPath(id:string){
        return `providers/${id}`;
    }
    getCompanyDetailsPath(viewType:string){
        return `providers/viewType/${viewType}`;
    }
    getCompanyWorkerInfoForCompanyPath(id:string){
        return `providers/users/company/${id}/display`;
    }
    getCompanyWorkersForCurrentSystemPath(){
        return `providers/worker/viewType/selectable`;
    }
    getCompanyByNamePath(name:string){
        return `providers/search/company/${name}`;
    }
    getCompanyIdCodePath(){
        return `providers/idCode`;
    }
    getConnectSystemToCompanyPath(){
        return `providers/anabsystems/companies`;
    }
    getAllSimilarCompanyPath(){
        return `providers/searchByCompany`;
    }
    getPromoteToCompanyAdminPath(companyId:string, userId:string){
        return `providers/company/${companyId}/worker/${userId}/Admin`;
    }
    getCompanyHasUserWithRolePath(id:string, role:string){
        return `providers/companies/${id}/hasRoles/${role}`;
    }
    getSearchCompanyWorkerByPhonenumberPath(companyId:string) {
        return `providers/search/worker/${companyId}`;
    }
    getSetCompanyWorkerEnabledPath(id:string){
        return `providers/worker/${id}/status`;
    }
    getResetExpirationForCompanyWorkerPath(companyId:string, userId:string){
        return `providers/company/${companyId}/worker/${userId}/expiration`;
    }
    getCompanyWorkerPath(companyId:string){
        return `providers/company/${companyId}/worker`;
    }
    getCompanyWorkerByIdPath(companyId:string, workerId:string){
        return `providers/company/${companyId}/worker/${workerId}`;
    }
}

class SettingsPathHelper {
    getGlobalSettingsPath(){
        return `settings/global`
    }
    getGlobalSettingByIdPath(id:number){
        return `settings/global/${id}`
    }
    getGlobalSettingsWithIdPath(id:number){
        return `settings/global/${id}`
    }
    getPronunciationSettingsPath(){
        return `settings/pronunciation`
    }
    getPronunciationSettingsWithIdPath(id:number){
        return `settings/pronunciation/${id}`
    }
    getCompanySettingsPath(){
        return `settings/company`
    }
    getClientSettingsPath(){
        return `settings/client`
    }
    getCompanyWorkerPersistancePath(){
        return `settings/dmaPersistence`
    }
    getExpiredTextPath(){
        return `settings/expiredText`
    }
    getDisdiSettingsPath(){
        return `settings/disdi`;
    }
    getDisdiUserSettingsPath(){
        return `settings/disdi/user`;
    }
    getDisdiSettingByIdPath(typeId:number){
        return `settings/zfv/type/${typeId}`;
    }
    getDefaultActivityTextPath(){
        return `settings/default/activity`;
    }
    getDefaultInstantMessageTextPath(){
        return `settings/default/instantmessage`;
    }
}

class CategoryPathHelper{
    getCategoriesPath():string {
        return `category`;
    }
    getCategoryByIdPath(id:string):string {
        return `category/id/${id}`;
    }
    getAllIconNamesPath(){
        return `category/icons/all`;
    }
    getAvailalbleIconNamesPath(){
        return `category/icons/unused`;
    }
}

class DisdiPathHelper{
    getDisdiByLocationPath(locationId:number){
        return `disdi/locations/${locationId}`;
    }
    getDisdiByRegistrationPath(regId:number){
        return `disdi/registration/${regId}`;
    }
    getAttachmentsPath(id:string){
        return `disdi/diaryEntries/${id}/attachments/1`;
    }
    getCheckAndUpdateHasAttachmentPropertyPath(id:string){
        return `disdi/diaryentries/${id}/attachment/checkandupdate`;
    }
    getDisdiPdfExportPath():string {
        return `disdi/pdf`;
    }
    getDisdiEntryByIdPath(id:string) {
        return `disdi/diaryEntries/${id}`;
    }
    getAttachmentUploadUrlPath(id:string){
        return `disdi/diaryEntries/${id}/attachments`;
    }
    getDeleteAttachmentFromBlobStoragePath(id:string, fileName:string){
        return `disdi/diaryEntries/${id}/attachments/${fileName}`;
    }
    getCreateEmtpyPath(){
        return `disdi/accesstype/frontend`;
    }
}