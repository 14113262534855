class PolicyHelper{
    public getPolicyFromRoute(route:string):string{
        // special case for providerDetails/companyWorkers & disdi:
        if (route.match(/\/providermanagement\/:?\w+/gm)?.some(()=>true)) return "ProviderManagementPolicy.CompanyWorker.Navigation.Show";
        if (route.match(/\/disdi\/\w+\/\w+/gm)?.some(()=>true)) return "Disdi.Read";

        switch(route){
            case "/locationRegistration":
            case "/locationRegistration/":
                return "LocationReport.Navigation.Show";
            case "/locationManagement":
            case "/locationManagement/":
                return "LocationManagement.Navigation.Show";
            case "/registrationReports":
            case "/registrationReports/":
                return "RegistrationReport.Navigation.Show";
            case "/anabmeldung":
            case "/anabmeldung/":
                return "Registartion.Manual";
            case "/safetyInstructions":
            case "/safetyInstructions/":
                return "SafetyInstruction.Navigation.Show";
            case "/users":
            case "/users/":
                return "UserAdministrator.User.Navigation.Show";
            case "/controlCenter":
            case "/controlCenter/":
                return "ControlCenter.Navigation.Show";
            case "/settings":
            case "/settings/":
                return "Setting.Navigation.Show";
            case "/group1":
            case "/group1/":
                return "Region.Navigation.Show";
            case "/group2":
            case "/group2/":
                return "Region.Navigation.Show";
            case "/activity":
            case "/activity/":
                return "ActivitySets.Show";
            case "/providermanagement":
            case "/providermanagement/":
                return "ProviderManagementPolicy.Read";
            case "/notifications":
            case "/notifications/":
                return "Notification.Navigation.Show";
            case "/category":
            case "/category/":
                return "Category.Read";
            default:
                return "";
        }
    }
}

export default new PolicyHelper();
