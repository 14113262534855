import {WebCallService} from "@/utilities/services/WebCallService";
import {PathHelper} from "@/utilities/helpers/PathHelper";
import {ActivitySetResponse, GeneralResponse} from "@/models/api/Responses";
import {HttpType} from "@/utilities/enums/Enums";
import {ActivitySetInfo} from "@/models/activity/ActivitySetInfo";
import {ActivitySetInfoRequest} from "@/models/api/Requests";

export class ActivitySetService {
    private webCaller: WebCallService;
    private uriHelper: PathHelper;

    constructor(){
        this.webCaller = new WebCallService();
        this.uriHelper = new PathHelper();
    }

    public async getActivitySets(viewType:string = "display"):Promise<ActivitySetResponse>{
        const uri = this.uriHelper.activitySets.getActivitySetsPath(viewType);

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async addOrUpdateActivitySet(activitySet:ActivitySetInfo, locationIds:{added:Array<number>, removed:Array<number>}):Promise<GeneralResponse>{
        const uri = activitySet.id < 0
            ? this.uriHelper.activitySets.getActivitySetBasePath()
            : this.uriHelper.activitySets.getSpecificActivitySetPath(activitySet.id);

        const requestBody = new ActivitySetInfoRequest(activitySet, locationIds);

        const reqType = activitySet.id < 0
            ? HttpType.post
            : HttpType.put;

        return await this.webCaller.HttpSend(reqType, uri, requestBody);
    }

    public async deleteActivitySet(id:number):Promise<GeneralResponse>{
        const uri = this.uriHelper.activitySets.getSpecificActivitySetPath(id);

        return await this.webCaller.HttpSend(HttpType.delete, uri);
    }
}
