import {ErrorLocationType, HttpType} from "@/utilities/enums/Enums";
import {useGlobalStore} from "@/stores/GlobalStore";
import jwt_decode from "jwt-decode";
import sleep from "@/utilities/helpers/Sleeper";
import router from "@/router";
import ErrorResponseHandler from "@/utilities/helpers/ErrorHandler";
import {PathHelper} from "@/utilities/helpers/PathHelper";
import {TokenResponse} from "@/models/api/Responses";
import {RefreshTokenRequest} from "@/models/api/Requests";
import {WebCallService} from "@/utilities/services/WebCallService";
import ErrorHandler from "@/utilities/helpers/ErrorHandler";

export class TokenService {
    private uriHelper: PathHelper;
    private isRefreshingToken:boolean = false;
    private webCaller:WebCallService;


    constructor(webcaller:WebCallService){
        this.webCaller = webcaller;
        this.uriHelper = new PathHelper();
    }

    public async EnsureFreshToken(path:string, type:HttpType):Promise<string>{
        // filter out login for token refresh
        if (this.doesntRequireToken(path)) return "";

        const globalStore = useGlobalStore();
        // hide errors but exclude deletion
        if(globalStore.error.location !== ErrorLocationType.none && type !== HttpType.delete) globalStore.error.location = ErrorLocationType.none;

        const convertedToken = jwt_decode(globalStore.token) as { exp:number }; // read token from store as object with exp-property of type number
        const expiration = (convertedToken.exp - 5) * 1000; // subtract 5 seconds from expiration to refresh token early and convert to milliseconds
        const now = Date.now();

        if(now > expiration) {
            await this.RefreshAccessToken();
        }

        return "done";
    }

    public async RefreshToken(accessToken:string):Promise<TokenResponse>{
        const uri = this.uriHelper.account.getRefreshTokenPath();
        const requestBody = new RefreshTokenRequest(accessToken);

        return await this.webCaller.HttpSend(HttpType.post, uri, requestBody);
    }

    /*
        checks if a refresh is already in progress and if so holds the api call till refresh is done
        if no refresh is in progresss this function refreshes the tokens
    */
    public async RefreshAccessToken():Promise<string>{
        let waitedForRefresh = false;

        // if refresh is in progress hold calls
        while(this.isRefreshingToken){
            console.log("waiting");
            await sleep(66);
            waitedForRefresh = true;
        }

        // drop call when it was hold by previous refresh of the token
        if (waitedForRefresh) return "done";

        this.isRefreshingToken = true;

        const globalStore = useGlobalStore();

        const accessToken = globalStore.token;

        const resp = await this.RefreshToken(accessToken);

        if(resp === null || resp === undefined || resp.statusCode !== 0){
            resp.statusCode = 401;
            await router.push("/login");

            ErrorResponseHandler.handleError(resp, ErrorLocationType.login);

            this.isRefreshingToken = false;

            return "error";
        }

        globalStore.setToken(resp.token);

        this.isRefreshingToken = false;

        return "done";
    }

    private doesntRequireToken(path:string):boolean{
        const whitelist = [
            this.uriHelper.account.getLoginPath(),
            this.uriHelper.account.getRefreshTokenPath(),
            this.uriHelper.account.getResetPasswordMailPath(),
            this.uriHelper.account.getResetPasswordPath(),
            this.uriHelper.misc.getStartupConfigPath(),
        ]

        return whitelist.includes(path);
    }
}