import {TableType} from "@/utilities/enums/Enums";
import router from "@/router";

/*
    checks for valid params of the route when opening shadowpage, since the disdi view needs to know what data to get (disdi for loaction or disdi for registration)
    only 3 'navigationParents are allowed':
        1) from LocationManagment (location)
        2) from LocationReports (registerLocation)
        3) from RegistrationReports (register)
 */
export default function checkValidParams ({next, to}:any) {
    const navigationParentsType = TableType[to.params.type as keyof typeof TableType];
    const isValidNavigationParent = [TableType.location, TableType.registerLocation, TableType.register]
                                        .includes(navigationParentsType);

    if (!isValidNavigationParent)
        router.push("/404");

    return next();
}