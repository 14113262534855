import { LocationInfo } from "@/models/location/LocationInfo";
import { SafetyInstructionInfo } from "@/models/safetyInstruction/SafetyInstructionInfo";
import { RegionInfo } from "@/models/group1/RegionInfo";
import { LevelInfo } from "@/models/group2/LevelInfo";
import { ControlCenterInfo } from "@/models/controlCenter/ControlCenterInfo";
import { PdfTemplateInfo } from "@/models/PdfTemplateInfo";
import { CoupledLocationInfo } from "@/models/location/CoupledLocationInfo";
import { CoupledSystemInfo } from "@/models/system/CoupledSystemInfo";
import { ForeignLocationInfo } from "@/models/location/ForeignLocationInfo";
import { UserPreference } from "@/models/user/UserPreference";
import { UserInfo } from "@/models/user/UserInfo";
import { SelectableRoleInfo } from "@/models/SelectableRoleInfo";
import { UserLocationPermissionInfo } from "@/models/user/UserLocationPermissionInfo";
import { StartupConfig } from "@/models/StartupConfig";
import {HubNotification} from "@/models/HubNotification";
import {RegisteredUserInfo} from "@/models/registration/RegisteredUserInfo";
import {LocationRegistrationInfo} from "@/models/registration/LocationRegistrationInfo";
import {UnRegisteredHistoryInfo} from "@/models/registration/UnRegisteredHistoryInfo";
import {CoupledRegistrationInfo} from "@/models/registration/CoupledRegistrationInfo";
import {CompanyInfo} from "@/models/company/CompanyInfo";
import {CompanyWorkerInfo} from "@/models/company/CompanyWorkerInfo";
import {ActivityInfo} from "@/models/activity/ActivityInfo";
import {ActivitySetInfo} from "@/models/activity/ActivitySetInfo";
import {PronunciationSetting} from "@/models/setting/PronunciationSetting";
import {GroupedSystemSettings} from "@/models/setting/GroupedSystemSettings";
import {CoupledSystemSetting} from "@/models/system/CoupledSystemSetting";
import {CategoryInfo} from "@/models/category/CategoryInfo";
import {DisdiInfo} from "@/models/disdi/DisdiInfo";
import {DisdiSetting} from "@/models/setting/disdi/DisdiSetting";
import {DisdiSettingWrapper} from "@/models/setting/disdi/DisdiSettingWrapper";
import {IconInfo} from "@/models/category/IconInfo";
import {GeneralRequest} from "@/models/api/Requests";
import {PdfFile} from "@/models/PdfFile";
import {ActivitySetting} from "@/models/setting/ActivitySetting";
import {RegistrationInfo} from "@/models/registration/RegistrationInfo";


export class GeneralResponse{
    statusCode: number;
    statusDescription: string;

    constructor(code?:number, description?:string) {
        this.statusCode = code ?? -1;
        this.statusDescription = description ?? "";
    }
}

export class DetailedLocationResponse extends GeneralResponse{
    locationInfo:LocationInfo = new LocationInfo();
}

export class LocationsResponse extends GeneralResponse{
    locationInfo: Array<LocationInfo> = new Array<LocationInfo>();
}

export class UsersResponse extends GeneralResponse{
    userInfo: Array<UserInfo> = new Array<UserInfo>();
}

export class UserResponse extends GeneralResponse{
    userInfo: UserInfo = new UserInfo();
}

export class SafetyInstructionResponse extends GeneralResponse{
    safetyInstructionInfo: Array<SafetyInstructionInfo> = new Array<SafetyInstructionInfo>();
}

export class RegionsResponse extends GeneralResponse{
    regionInfo: Array<RegionInfo> = new Array<RegionInfo>();
}

export class LevelsResponse extends GeneralResponse{
    levelInfo: Array<LevelInfo> = new Array<LevelInfo>();
}

export class ControlCentersResponse extends GeneralResponse{
    controlCenterInfo: Array<ControlCenterInfo> = new Array<ControlCenterInfo>();
}

export class PdfTemplatesResponse extends GeneralResponse{
    selectablePDFTemplate: Array<PdfTemplateInfo> = new Array<PdfTemplateInfo>();
}

export class CoupledSystemsResponse extends GeneralResponse{
    coupledSystemInfo: Array<CoupledSystemInfo> = new Array<CoupledSystemInfo>();
}

export class ForeignLocationsResponse extends GeneralResponse{
    availableLocations:Array<ForeignLocationInfo> = new Array<ForeignLocationInfo>();
    coupledLocations:Array<ForeignLocationInfo> = new Array<ForeignLocationInfo>();
}

export class CoupledLocationsResponse extends GeneralResponse{
    coupledLocationsInfo:Array<CoupledLocationInfo> = new Array<CoupledLocationInfo>();
}

export class UserPreferenceResponse extends GeneralResponse{
    preferenceInfo:Array<UserPreference> = new Array<UserPreference>();
}

export class TokenResponse extends GeneralResponse{
    token:string = "";
}

export class SelectableRoleResponse extends GeneralResponse{
    roleInfo: Array<SelectableRoleInfo> = [];
}

export class UserLocationPermissionResponse extends GeneralResponse{
    userLocationsPermissionInfo: Array<UserLocationPermissionInfo> = [];
}

export class CSVImportResponse extends GeneralResponse{
    hTMLReport:string = "";
}

export class ExportPDFResponse extends GeneralResponse{
    pdfContentResult:PdfFile|null = null;
    locationStatusReport:string = "";
}

export class UserIdNumberResponse extends GeneralResponse{
    identifikationNumber:string = "";
}

export class StartupConfigResponse extends GeneralResponse{
    startupInfo:StartupConfig = new StartupConfig();
    activityInfo:ActivitySetting = new ActivitySetting();
    disdiInfo:DisdiSetting = new DisdiSetting();
    enabledFeatures:Array<string> = [];
    preferenceInfo:Array<UserPreference> = [];
    companyName:string = "";
    companyIdCode:string = "";
}

export class HubNotificationResponse extends GeneralResponse{
    notifications:Array<HubNotification> = [];
}

export class RegisteredUserResponse extends GeneralResponse{
    registrationInfo: Array<RegisteredUserInfo> = [];
}

export class LocationRegistrationResponse extends GeneralResponse {
    locationRegistrationInfo: Array<LocationRegistrationInfo> = [];
}

export class LocationRegistrationHistoryResponse extends GeneralResponse {
    locationInfo: Array<LocationRegistrationInfo> = [];
}

export class RegistrationHistoryResponse extends GeneralResponse {
    unRegisteredInfo: Array<UnRegisteredHistoryInfo> = [];
}

export class LocationUsersResponse extends GeneralResponse {
    locationInfo:Array<RegisteredUserInfo> = [];
}

export class CoupledRegistrationResponse extends  GeneralResponse {
    registrationInfo:Array<CoupledRegistrationInfo> = [];
}

export class RegistrationResponse extends GeneralResponse {
    regList:Array<RegistrationInfo> = [];
}

export class CompanyInfoResponse extends GeneralResponse {
    anAbCompaniesInfo:Array<CompanyInfo> = [];
    companyInfo:CompanyInfo = new CompanyInfo();
}

export class CompanyWorkersResponse extends GeneralResponse {
    companyWorkers:Array<CompanyWorkerInfo> = [];
}

export class CompamyWorkersUserInfoResponse extends GeneralResponse {
    userInfos: Array<UserInfo> = [];
}

export class ActivityResponse extends GeneralResponse {
    activityInfo: Array<ActivityInfo> = [];
}

export class CompaniesResponse extends GeneralResponse {
    companiesInfo:Array<CompanyInfo> = [];
}

export class CompanyIdCodeResponse extends GeneralResponse {
    companyIdCode:string = "";
}

export class CompanyIdResponse extends GeneralResponse {
    companyId:string = "";
}

export class ExistsResponse extends GeneralResponse {
    isExist:boolean = false;
}

export class AddedCompanyWorkerResponse extends GeneralResponse {
    userId:string = "";
}

export class ActivitySetResponse extends GeneralResponse {
    activitySetInfo:Array<ActivitySetInfo> = [];
}

export class SettingsResponse extends GeneralResponse {
    settingInfo:Array<GroupedSystemSettings | PronunciationSetting | CoupledSystemSetting> = [];
}

export class TextResponse extends GeneralResponse {
    text:string = "";
}

export class CompanySettingsResponse extends GeneralResponse {
    dmaPersistence:string = "";
    expiredText:string = "";
}

export class ChangelogResponse extends GeneralResponse {
    log:string = "";
}

export class CompanyWorkerSearchResponse extends GeneralResponse {
    statusMessage:string = "";
    user:CompanyWorkerInfo | undefined = undefined;
}

export class IdResponse extends GeneralResponse{
    id:number = -1;

}

export class GuidResponse extends GeneralResponse{
    id:string = ""; //GUID format
}

export class CategoryByIdResponse extends GeneralResponse{
    category:CategoryInfo|null = null;
}

export class CategoriesResponse extends GeneralResponse{
    categories:Array<CategoryInfo>|null = null;
}

export class DisdiResponse extends GeneralResponse{
    diaryEntries:Array<DisdiInfo>|null = null;
}

export class DisdiSettingResponse extends GeneralResponse{
    disdiSetting:DisdiSettingWrapper|null = null;
}

export class AttachmentResponse extends GeneralResponse{
    diaryEntryAttachments:Array<string>|null = null;
}

export class DiaryEntryResponse extends GeneralResponse{
    diaryEntry:DisdiInfo|null = null;
}

export class IconsResponse extends GeneralResponse{
    iconInfo:Array<IconInfo>|null = null;
}

export class DiaryEntryAttachmentResponse extends GeneralResponse{
    diaryEntryAttachments:Array<string> = [];
}
