import {AnAbSystemInfo} from "@/models/system/AnAbSystemInfo";

export class CompanyWorkerInfo {
    // anabSystem === companyWorkerAnAbSystem-entry so it hold user-specific-data
    anAbSystem:AnAbSystemInfo = new AnAbSystemInfo();
    anAbSystemId:string = "";
    companyId:string = "";
    email:string = "";
    userId:string = "";
    id:string = this.userId; // ghost property not given from backend, needed for bryntumgrid
    isAdmin:boolean = false;
    isEnabled:boolean = false;
    dialCode:string = "";
    phoneNumber:string = "";
    firstName:string = "";
    lastName:string = "";
    fullName: string = `${this.firstName} ${this.lastName}`;
    userInfo:string = "";
    isAnonymous:boolean = false;
    sendVerificationEmailDate?:Date = undefined;
}