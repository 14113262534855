<template>
  <div>
    <div class="block w-full">

      <TabsWrapper :activeTab="activeTab">
        <TabItem :title="$t('popups.disdiDetails.tabs.details')">
          <DisdiDetailTab :data="popupData"/>
        </TabItem>
        <TabItem :title="$t('popups.disdiDetails.tabs.locationDetails')">
          <DisdiLocationDetailsTab :data="popupData"/>
        </TabItem>
        <TabItem  :title="$t('popups.disdiDetails.tabs.attachments')">
          <DisdiAttachmentTab :data="popupData"/>
        </TabItem>
      </TabsWrapper>

    </div>
  </div>
</template>

<script setup>
import {defineProps, onMounted, ref, toRef} from "vue";
import DisdiAttachmentTab from "@/components/tabs/disdi/DisdiAttachmentTab";
import DisdiDetailTab from "@/components/tabs/disdi/DisdiDetailTab.vue";
import TabsWrapper from "@/components/tabs/TabsWrapper";
import TabItem from "@/components/tabs/TabItem";
import DisdiLocationDetailsTab from "@/components/tabs/disdi/DisdiLocationDetailsTab.vue";

let activeTab = ref(0);
const props = defineProps({
  data: Object
});
const popupData = toRef(props, 'data');

onMounted(()=>{
  console.log(popupData)
})
</script>