import {WebCallService} from "@/utilities/services/WebCallService";
import {PathHelper} from "@/utilities/helpers/PathHelper";
import {CoupledSystemsResponse, GeneralResponse, SettingsResponse} from "@/models/api/Responses";
import {HttpType} from "@/utilities/enums/Enums";
import {CoupledSystemSetting} from "@/models/system/CoupledSystemSetting";
import {CoupledSystemRequest} from "@/models/api/Requests";

export class CoupledSystemService {
    private webCaller: WebCallService;
    private uriHelper: PathHelper;

    constructor(){
        this.webCaller = new WebCallService();
        this.uriHelper = new PathHelper();
    }

    public async getCoupledSystems(viewtype: string = "selectable"): Promise<CoupledSystemsResponse> {
        const uri = this.uriHelper.coupledSystems.getCoupledSystemsPath() + `/viewType/${viewtype}`;

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async getLocationsForForeignSystem(id: number): Promise<any> {
        const uri = this.uriHelper.coupledSystems.getAvailableLocationsForForeignSystemPath(id);

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async getCoupledSystemSettings(): Promise<SettingsResponse> {
        const uri = this.uriHelper.coupledSystems.getCoupledSystemsPath();

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async addOrUpdateCoupledSystemSetting(cs: CoupledSystemSetting): Promise<GeneralResponse | any> { // any type not good senior heff
        const uri = cs.id < 0
            ? this.uriHelper.coupledSystems.getCoupledSystemsPath()
            : this.uriHelper.coupledSystems.getCoupledSystemByIdPath(cs.id);
        const requestBody = new CoupledSystemRequest(cs);
        const reqType = cs.id < 0
            ? HttpType.post
            : HttpType.put;

        return await this.webCaller.HttpSend(reqType, uri, requestBody);
    }

    public async deleteCoupledSystemSetting(id: number): Promise<GeneralResponse> {
        const uri = this.uriHelper.coupledSystems.getCoupledSystemByIdPath(id);

        return await this.webCaller.HttpSend(HttpType.delete, uri);
    }
}