import {createI18n} from "vue-i18n";
import deLanguage from '@/locales/de.json';
import enLanguage from '@/locales/en.json';

const messages = {
    de: deLanguage,
    en: enLanguage
};

const i18n = createI18n({
    locale: 'de',
    fallbackLocale: 'en',
    legacy: false,
    globalInjection: true,
    messages
});

const $t = i18n.global.t;

export { i18n, $t };