import {
    GeneralResponse,
    LocationRegistrationResponse,
    LocationUsersResponse,
    RegisteredUserResponse, RegistrationResponse
} from "@/models/api/Responses";
import {ChangeActivityRequest, ManualRegistrationRequest} from "@/models/api/Requests";
import {HttpType} from "@/utilities/enums/Enums";
import {WebCallService} from "@/utilities/services/WebCallService";
import {PathHelper} from "@/utilities/helpers/PathHelper";

export class RegistrationService {
    private webCaller: WebCallService;
    private uriHelper: PathHelper;

    constructor(){
        this.webCaller = new WebCallService();
        this.uriHelper = new PathHelper();
    }


    public async postRegisterUser(userId:string, locationId:number, activityText:string|undefined = undefined, guestCount:number = 0):Promise<GeneralResponse>{
        const uri = this.uriHelper.registrations.getManualRegistrationPath();
        const requestBody = new ManualRegistrationRequest(userId, locationId, activityText, guestCount);

        return await this.webCaller.HttpSend(HttpType.post, uri, requestBody);
    }

    public async postUnRegisterUser(userId:string, locationId:number):Promise<GeneralResponse>{
        const uri = this.uriHelper.registrations.getManualRegistrationPath();
        const requestBody = new ManualRegistrationRequest(userId, locationId, undefined, 0);

        return await this.webCaller.HttpSend(HttpType.put, uri, requestBody);
    }

    public async getOpenRegistrationForUser(userId:string):Promise<RegistrationResponse>{
        const uri = this.uriHelper.registrations.getOpenRegistrationForUserPath(userId);

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    // LOCATION REPORTS

    public async getLocationReports():Promise<LocationRegistrationResponse>{
        const uri = this.uriHelper.registrations.getLocationReportsPath();

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async getUserInfoForLocation(id:number):Promise<LocationUsersResponse> {
        const uri = this.uriHelper.registrations.getLocationUserReportsPath(id);

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    // REGISTRATION REPORTS

    public async getRegistrationReports():Promise<RegisteredUserResponse>{
        const uri = this.uriHelper.registrations.getRegistrationReportsPath();

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async getOpenRegistrationsByUsers():Promise<RegisteredUserResponse>{
        const uri = this.uriHelper.registrations.getOpenRegistrationsByUsersPath();

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async postChangeActivityForRegistration(registrationId:number, activityId:number | undefined, activityText:string):Promise<GeneralResponse>{
        const uri = this.uriHelper.registrations.getChangeActivityForRegistrationPath(registrationId);
        const requestBody = new ChangeActivityRequest(activityId, activityText);

        return await this.webCaller.HttpSend(HttpType.post, uri, requestBody);
    }
}
