<template>
  <div>

    <div class="block w-full">
      <div class="grid grid-cols-2 gap-4 w-full mb-5">
        <div>
          <div class="inputTitle">
            {{ $t('popups.settings.spokenText.intern') }}
          </div>
          <div class="inputField flex-grow" >
            <div :class="{ error: v$.name.$errors.length }">
              <input type="text" :value="popupData.internalIdentification" class="w-full" disabled/><br/>
            </div>
          </div>
        </div>
        <div>
          <div class="inputTitle">
            {{ $t('popups.settings.spokenText.description') }} *
          </div>
          <div class="inputField flex-grow" >
            <div :class="{ error: v$.name.$errors.length }">
              <input type="text" v-model="popupData.name" class="w-full" /><br/>
              <div class="input-errors" v-for="error of v$.name.$errors" :key="error.$uid">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 gap-4 w-full">
        <div>
          <div class="inputTitle">
            {{ $t('popups.settings.spokenText.spokenText') }} *
          </div>
          <div class="inputField flex-grow" >
            <div :class="{ error: testCallValidator.pronunciation.$errors.length }">
              <div :class="{ error: v$.spokenText.$errors.length }">
                <textarea class="form-control w-full" type="text" rows="4" v-model="popupData.value"></textarea> <br/>
                <div class="input-errors" v-for="error of v$.spokenText.$errors" :key="error.$uid">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
                <div class="input-errors" v-for="error of testCallValidator.pronunciation.$errors" :key="error.$uid">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-html="popupData.helpText" class="helpText"></div>
        </div>
      </div>
      <div class="my-8">
        <hr/>
      </div>
      <div class="grid grid-cols-1 gap-4 w-full">
        <div>
          <div class="block w-full">
            <h3 class="mb-4">{{ $t('popups.locationInfo.readerTitle') }}
              <span class="tooltip infoIcon">
					<span class="icon-info"></span>
					<span class="infoText tooltiptext shadow">
						{{ $t('popups.locationInfo.readerTooltip') }}
					</span>
				</span>
            </h3>
            <div class="grid grid-cols-2 gap-4 w-full">
              <div>
                <div class="inputTitle">
                  {{ $t('popups.locationInfo.readerPhonenumber') }}
                </div>
                <div class="inputField flex-grow" >
                  <div :class="{ error: testCallValidator.numberToCall.$errors.length }">
                    <Text v-model="callNumber" @focusout="hideCallValidatorErrors"/>
                    <div class="input-errors" v-for="error of testCallValidator.numberToCall.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                  <button class="primary mt-2" @click="voiceTestCall" :disabled="loading">
                    <TextOrSpinner :text="$t('popups.reader.button')" :spin="loading"/>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import {computed, defineProps, ref, toRef} from "vue";
import {useI18n} from "vue-i18n";
import {helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import {MiscService} from "@/utilities/services/entities/MiscService";
import Text from "@/components/inputs/Text";
import TextOrSpinner from "@/components/TextOrSpinner";
import {$t} from "@/utilities/i18n/config";


const { t } = useI18n();

const callNumber = ref("");

const props = defineProps({
  data: Object
})

const popupData = toRef(props, 'data');
const miscService = new MiscService();

const loading = ref(false);

const voiceTestCall = async () => {
  loading.value = true;

  const isValid = await testCallValidator.value.$validate();

  if(!isValid) {
    loading.value = false;

    const targetElement = document.querySelector(".error");
    targetElement.scrollIntoView({behavior: "smooth", block: 'center'});

    return;
  }

  // sadly can't get generalResponse here so it is fire and forget
  await miscService.postVoiceTestCall(popupData.value.value.replace(/\{\d\}/g, "Platzhalter"), callNumber.value);

  loading.value = false;
};

// Validation

const rules = computed(() => {
  const rules = {
    name: { required: helpers.withMessage(t('validators.required'), required) },
    spokenText: { required: helpers.withMessage(t('validators.required'), required) },
  }
  return rules
})
const v$ = ref(useVuelidate(rules, popupData))

const phoneNumbersValidator = helpers.regex(/^\+[0-9]+$/);
const readerRules = computed(()=>{
  return {
    pronunciation: {
      required: helpers.withMessage(
          $t('validators.required'),
          required
      )
    },
    numberToCall: {
      phoneNumbersValidator: helpers.withMessage(
          $t('validators.phoneNumber'),
          phoneNumbersValidator
      ),
      required: helpers.withMessage(
          $t('validators.required'),
          required
      )
    },
  }
})
const callData = computed(()=>{
  return {pronunciation: popupData.value.value, numberToCall: callNumber.value}
})
const testCallValidator = ref(useVuelidate(readerRules, callData));
const hideCallValidatorErrors = ()=>{
  testCallValidator.value.$reset();
}

</script>

<style scoped>

</style>
