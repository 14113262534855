import {WebCallService} from "@/utilities/services/WebCallService";
import {PathHelper} from "@/utilities/helpers/PathHelper";
import {
    ChangelogResponse,
    HubNotificationResponse,
    PdfTemplatesResponse,
    StartupConfigResponse
} from "@/models/api/Responses";
import {ErrorLocationType, HttpType} from "@/utilities/enums/Enums";
import {VoiceTestRequest} from "@/models/api/Requests";
import {OidcMetadata} from "oidc-client";
import ErrorHandler from "@/utilities/helpers/ErrorHandler";


export class MiscService {
    private webCaller: WebCallService;
    private uriHelper: PathHelper;

    constructor(){
        this.webCaller = new WebCallService();
        this.uriHelper = new PathHelper();
    }

    public async getPdfTemplates():Promise<PdfTemplatesResponse> {
        const uri = this.uriHelper.misc.getPdfTemplatesPath();

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async postVoiceTestCall(text:string, callNumber:string):Promise<any> {
        const uri = this.uriHelper.misc.getVoiceTestCallPath();
        const requestBody = new VoiceTestRequest(text, callNumber);

        return await this.webCaller.HttpSend(HttpType.post, uri, requestBody);
    }

    public async getStartupConfig(errorLocationType:ErrorLocationType):Promise<StartupConfigResponse|null> {
        try{
            const uri = this.uriHelper.misc.getStartupConfigPath();

            const resp = await this.webCaller.HttpSend(HttpType.get, uri) as StartupConfigResponse;
            if (resp === null || resp === undefined || resp.statusCode !== 0) {
                ErrorHandler.handleError(resp, errorLocationType);
                console.log("CONFIG LOADING FAILED!", resp);
                return null;
            }

            console.log("CONFIG LOADED", resp);
            return resp;
        }
        catch (e){
            console.log("CONFIG LOADING FAILED!", e);
            return null;
        }
    }

    public async getHubNotifications():Promise<HubNotificationResponse> {
        const uri = this.uriHelper.misc.getHubNotificationsPath();

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async getChangelog():Promise<ChangelogResponse> {
        const uri = this.uriHelper.misc.getChangelogPath();

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async getOidcMetaData(url:string):Promise<OidcMetadata> {
        return (await this.webCaller.GetFromExtrenalAPI(url)).data as OidcMetadata ;
    }
}