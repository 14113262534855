export enum HttpType {
    post,
    get,
    delete,
    put,
}

export enum PdfExportActionEnum {
    download,
    email,
}

export enum PopupType {
    none,
    locationEditor,
    locationFilterPopup,
    locationCouplingPopup,
    userFilterPopup,
    userPopup,
    csvImportPopup,
    group2Popup,
    group2Locations,
    controlCenterEditor,
    regionEditor,
    registrationUserHistory,
    registrationFilterPopup,
    safetyInstructions,
    safetyInstructionLocationsPopup,
    locationReportsPopup,
    locationReportsDetailsPopup,
    locationReportsFilterPopup,
    locationReportsUserHistoryPopup,
    registerPopup,
    activitySetLocationsPopup,
    activitySetEditPopup,
    activityEditPopup,
    providerManagementEditPopup,
    providerNewCompanyWorkerPopup,
    providerCompanyWorkerEditPopup,
    selectSimilarCompany,
    settingsEditSpokenText,
    settingsEditSystemSetting,
    settingsEditCoupledSystems,
    settingsResetCredentials,
    changeActivityForRegistration,
    categoryEditor,
    disdiDetailsPopup,
    disdiFilterPopup,
    locationDisdiEditor
}

export enum ErrorType {
    warning,
    error,
}

export enum ErrorLocationType {
    none,
    login,
    popup,
    table,
    form,
    disdiMaintenance
}

export enum RegistrationStatusType {
    isBusy,
    wasBusy,
    notBusy,
}

export enum PreferenceType {
    filter = 1,
    hiddenColumn = 2,
    general = 3,
    columnOrder = 4,
}

export enum TableType {
    location,
    user,
    controlCenter,
    group1,
    group2,
    register,
    registerLocation,
    activitySetLocations,
    safetyInstructionLocations,
    activitySet,
    activitySetEditLocation,
    activity,
    locationReportsUserHistory,
    coupledLocation,
    availableCouplingLocation,
    locationsPopup,
    similarCompany,
    userRegistrationHistory,
    safetyInstruction,
    coupledSystem,
    systemSetting,
    notification,
    provider,
    providerStaff,
    spokenText,
    category,
    disdi,
}

export enum CouplingActionType {
    couple,
    decouple,
}

export enum CoupledSafetyInstructionActionType {
    accept,
    decline
}

export enum ZdvSettingType{
    disdi = 1
}

export enum SsoType {
    none,
    oauth,
    saml
}

export enum NotifyType {
    info,
    success,
    error
}

export enum DisdiStateType{
    NoDISDI,
    SomeLocations,
    AllLocations
}

export enum DisdiMetadataType{
    None,
    OnlyRegistration,
    OnlyUnregistration,
    Both
}