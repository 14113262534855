export class AttributeFilter{
    tableName:string;
    attributeName:string;
    attributeValue:string;

    constructor(tableName:string, attribute:string, value:string) {
        this.tableName = tableName;
        this.attributeName = attribute;
        this.attributeValue = value;
    }
}