import { useGlobalStore } from "@/stores/GlobalStore";
import { anabPolicies } from "@/AppConfig";
import {usePermissionStore} from "@/stores/PermissionStore";
import {controlLayersProps} from "@vue-leaflet/vue-leaflet/dist/src/functions/controlLayers";

export class PermissionService {
    /*
        gets identifier of policy to look for, and checks if the user has one of the roles listed in the policy-roles

        returns false when user is not set
     */
    public userHasPermission(policyName:string):boolean{
        const globalStore = useGlobalStore();

        const roleList = this.getRolesFromPolicyList(policyName);

        if (globalStore.user === null || globalStore.user === undefined) return false;

        const roles = globalStore.user.roles;

        return roles.some((i:any) => roleList.includes(i));
    }

    /*
        takes a policy name and a new role-string to update anabPolicy array-entry via splice+push
     */
    public updateDynamicPolicy(policyName:string, roles:string){
        const permissionStore = usePermissionStore();

        const policy = permissionStore.anabPolicies.find(p => p.Name === policyName);

        if(policy === undefined) return;

        policy.Roles = roles;
        permissionStore.anabPolicies.splice(permissionStore.anabPolicies.indexOf(policy),1);
        permissionStore.anabPolicies.push(policy)
    }


    /* --- PRIVATE  --- */


    private getRolesFromPolicyList(policyName:string):Array<string>{
        const permissionStore = usePermissionStore();
        const foundPolicy = permissionStore.anabPolicies.find((i:any) => i.Name === policyName);

        if (foundPolicy === undefined) return new Array<string>(); //TODO error handling?

        return foundPolicy.Roles.split(/,\s*/g);
    }
}
