import { createApp } from 'vue';
import '@/assets/css/tailwind.css';
import '@/assets/css/fonts.css';
import App from './App.vue';
import router from './router';
import Notifications from '@kyvg/vue3-notification';
import { createPinia } from 'pinia';
import { i18n } from "@/utilities/i18n/config";
import signalR from "@/utilities/signalR/signalRPlugin";
import {PermissionService} from "@/utilities/services/PermissionService";

const pinia = createPinia();

// persist pina and load from local store
pinia.use((context)=>{
    const id = context.store.$id;

    const fromStorage = window.localStorage.getItem(id);
    if(fromStorage){
        context.store.$patch(JSON.parse(fromStorage));
    }

    context.store.$subscribe((mutation, state)=>{
        window.localStorage.setItem(id, JSON.stringify(state));
    });
});


const app = createApp(App);

app.directive('permission', (el, binding, vnode) => {
    const permissionService = new PermissionService();
    const permissionCheck = permissionService.userHasPermission(binding.value);

    if(permissionCheck) {
        return vnode.el.hidden = false;
    } else {
        // remove node not just hiding
        if(vnode.el.parentElement) {
            return vnode.el.parentElement.removeChild(vnode.el);
        }
    }
})


app.use(i18n);
app.use(router);
app.use(Notifications);
app.use(pinia);
app.use(signalR);

app.mount('#app');

export const globalProps = app.config.globalProperties;

