import * as signalR from "@microsoft/signalr";
import funCollection from "./signalRFunctionCollection";
import {baseURL} from "@/AppConfig";
import {notify} from "@kyvg/vue3-notification";
import {NotifyType} from "@/utilities/enums/Enums";
import {$t} from "@/utilities/i18n/config";

/*
    this plugin builds and configures a connection to our signalR hub and starts the connection on app build
    it then provides the connection object to the app via a globalProperty (signalRConnection) to be able to send to the backend
*/
export default {
    install: async (app:any, options:object) => {
        const hubUrl = baseURL+"/hub";

        try {
            const connection = new signalR.HubConnectionBuilder()
                .withUrl(hubUrl)
                .configureLogging(signalR.LogLevel.Error)
                .withAutomaticReconnect()
                .build();

            // register all needed function to listen to
            funCollection.forEach((i) => {
                connection.on(i.key, i.method);
            });

            connection.onclose((e)=>{
                notify({
                    title: $t("notifications.signalrDisconnected.title"),
                    text: $t('notifications.signalrDisconnected.text'),
                    duration: -1,
                    type: NotifyType[NotifyType.error]
                });
            })

            // set keepAlive time to 10 minutes
            connection.keepAliveIntervalInMilliseconds = 1000*60*10

            await connection.start().catch((e) => console.log(e));

            app.config.globalProperties.signalRConnection = connection;
        }
        catch(e){
            console.log(e);
        }
    }
}