<template>
	<div class="tabs">
		<ul class="tabs__header">
        <li
            v-for="(tab, index) in tabTitlesWithoutHidden"
            :key="index"
            class="tabs__item"
            :class="{ selected: selectedTitle === tab.title}"
            @click="clickTab(index)"
        >
          <span v-if="!tab.hidden">{{ tab.title }}</span>
        </li>
		</ul>
		<slot />
	</div>
</template>

<script setup>
import {useSlots, ref, provide, defineProps, watch, computed} from 'vue'
const slots = useSlots()
const tabTitles = ref(slots.default().map(tab => ({title: tab.props.title, hidden: tab.props.hidden})))
const props = defineProps({
	activeTab: Number,
  hidden: Boolean,
})
const tabTitlesWithoutHidden = computed(()=>{
  return tabTitles.value.filter(item => !item.hidden);
});

const selectedTitle = ref(tabTitlesWithoutHidden.value[props.activeTab].title)

provide('selectedTitle', selectedTitle)

watch(() => props.activeTab, (newValue) => {
	selectedTitle.value = tabTitlesWithoutHidden.value[newValue].title;
});

const clickTab = (index) => {
	selectedTitle.value = tabTitlesWithoutHidden.value[index].title;
}



</script>

<style lang="scss">

</style>
