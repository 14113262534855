import {WebCallService} from "@/utilities/services/WebCallService";
import {PathHelper} from "@/utilities/helpers/PathHelper";
import {LevelsResponse} from "@/models/api/Responses";
import {HttpType} from "@/utilities/enums/Enums";
import {LevelInfo} from "@/models/group2/LevelInfo";
import {LevelRequest} from "@/models/api/Requests";

export class Group2Service {
    private webCaller: WebCallService;
    private uriHelper: PathHelper;

    constructor(){
        this.webCaller = new WebCallService();
        this.uriHelper = new PathHelper();
    }

    public async getLevels(viewtype: string = "selectable"):Promise<LevelsResponse> {
        const uri = this.uriHelper.levels.getLevelsPath() + `/viewtype/${viewtype}`;

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async addOrUpdateLevel(level:LevelInfo):Promise<any>{
        const uri = level.id < 0
            ? this.uriHelper.levels.getLevelsPath()
            : this.uriHelper.levels.getLevelPath(level.id);
        const requestBody = new LevelRequest(level);
        const reqType = level.id < 0
            ? HttpType.post
            : HttpType.put;

        return await this.webCaller.HttpSend(reqType, uri, requestBody);
    }

    public async deleteLevel(id:number):Promise<any>{
        const uri = this.uriHelper.levels.getLevelPath(id);

        return await this.webCaller.HttpSend(HttpType.delete, uri);
    }

}
