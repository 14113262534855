import {LocationInfo} from "@/models/location/LocationInfo";

export class SafetyInstructionInfo {
    isAccepted:boolean = false;
    coupledLocationId?:number = undefined;
    id:number = -1;
    displayOrder:number = 0;
    name:string = "";
    text:string = "";
    safetyInstructionLocations:Array<LocationInfo> = [];

    constructor(id?:number, index?:number, name?:string) {
        this.isAccepted = false;
        this.coupledLocationId = undefined;
        this.id = id ?? -1;
        this.displayOrder = index ?? 0;
        this.name = name ?? "";
        this.text = "";
        this.safetyInstructionLocations = [];
    }
}