import { useGlobalStore } from "@/stores/GlobalStore";
import ErrorHandler from "@/utilities/helpers/ErrorHandler";
import { ErrorLocationType } from "@/utilities/enums/Enums";
import { GeneralResponse } from "@/models/api/Responses";
import router from "@/router";
import {MiscService} from "@/utilities/services/entities/MiscService";


/*
    check if user needs to reauthenticate and reroute path to login-page
*/
export default async function reactToRouting ({next}:any){
    const globalStore = useGlobalStore();

    const token = globalStore.token;
    const user = globalStore.user;

    const resp = new GeneralResponse();
    if(token === null || token === undefined || token.match(/^\w*$/) !== null) {
        await router.replace("/login");

        resp.statusCode = 401;

        ErrorHandler.handleError(resp, ErrorLocationType.login);
    }
    else if(user === null || user === undefined) {
        await router.replace("/login");

        resp.statusCode = 401;

        ErrorHandler.handleError(resp, ErrorLocationType.login);
    }

    // make small API call to check refresh expiration
    await (new MiscService()).getHubNotifications()

    return next();
}
